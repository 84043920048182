<template>
  <el-aside>
    <div class="left">
      <el-tree
        :data="funList"
        :props="defaultProps"
        accordion
        @node-click="handleNodeClick"
        node-key="id"
        :default-expanded-keys="menuid"
      >
      </el-tree>
    </div>
  </el-aside>
</template>

<script>
export default {
  data() {
    return {
      menuid: [],
      funList: [],
      allFunList: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },
  mounted() {
    this.menuid = [this.session("menuid")];
    this.buildfun();
  },
  methods: {
    buildfun() {
      let funList = JSON.parse(this.session("functionList"));
      this.allFunList = funList;
      if (funList != null) {
        funList = funList.filter((e) => e.isShow);
        funList = this.sortByKey(funList, "fOrder");
        funList.forEach((item) => {
          if (item.fpid == this.emptyId) {
            let fun = {
              id: item.id,
              label: item.functionName,
              fController: item.fController,
              fAction: item.fAction,
              fpid: item.fpid,
              children: this.buildchild(item.id, funList),
            };

            this.funList.push(fun);
          }
        });
      }
    },
    buildchild(fpid, funList) {
      var childrenlist = [];
      funList.forEach((item) => {
        if (item.fpid == fpid) {
          let fun = {
            id: item.id,
            label: item.functionName,
            fController: item.fController,
            fAction: item.fAction,
            fpid: item.fpid,
            children: this.buildchild(item.id, funList),
          };

          childrenlist.push(fun);
        }
      });
      return childrenlist;
    },
    handleNodeClick(data) {
      if (data.fAction != "") {
        sessionStorage.setItem("menuid", data.id);
        this.getParents(data);
        let url =
          data.fController == ""
            ? data.fAction
            : data.fController + "/" + data.fAction;
        this.$router.push(url);
      }
    },
    getParents(data) {
      let list = [data.label];
      while (true) {
        let parent = this.allFunList.find((item) => item.id == data.fpid);
        if (parent == null) break;
        list.push(parent.functionName);
        data = parent;
      }
      list.push(this.SystemShortTitle);
      sessionStorage.setItem("breadcrumb", list.reverse());
    },
  },
};
</script>

<style scoped>
.left {
  /* margin-top: 10rem; */
  width: 13rem;
  height: 45rem;
  margin-left: 4rem;
  color: white;
  font-size: 15px;
  overflow-y: auto;
  overflow-x: hidden;
  background: rgba(0, 0, 0, 0.2);
  border: 1px rgb(0, 119, 136) solid;
  position: absolute;
  z-index: 1000;
}

</style>