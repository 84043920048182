<template>
  <div class="addFunc_title">添加测量部位</div>
  <hr />
  <div class="xgmm_form">
    <el-form
      ref="addForm"
      :model="modelForm"
      :rules="this.testpositionlogic.rules"
    >
      <el-row style="text-align: center">
        <el-col :span="4" align="right"> 测量部位名称： </el-col>
        <el-col :span="6">
          <el-form-item prop="testPText">
            <el-input
              class="admin_tel input77"
              v-model="modelForm.testPText"
              maxlength="30"
              placeholder="请输入测量部位名称"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right"> 测量部位编码： </el-col>
        <el-col :span="6">
          <el-form-item prop="testPCode">
            <el-input
              class="admin_tel input77"
              v-model="modelForm.testPCode"
              maxlength="30"
              placeholder="请输入测量部位编码"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="sureBtn">
      <el-button class="qd" @click="okAdd('addForm')" type="primary">
        确定
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modelForm: { testPText: "", testPCode: "" },
    };
  },
  methods: {
    okAdd: function (ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.axios
            .post(
              this.baseURL + "Base/AddTestPosition",
              this.modelForm,
              this.headers()
            )
            .then((res) => {
              this.resolveData(res.data, "add");
            });
        }
      });
    },
  },
};
</script>

<style>
</style>