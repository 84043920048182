<template>
  <div class="list">
    <div class="addFunc_title">添加文章</div>
    <hr />
    <el-form
      ref="addArticleForm"
      :model="articleForm"
      :rules="this.articlelogic.rules"
    >
      <el-row :gutter="20">
        <el-col :span="3" align="right"> 文章标题： </el-col>
        <el-col :span="21">
          <el-form-item prop="articleTitle">
            <el-input
              v-model="articleForm.articleTitle"
              placeholder="请输入文章标题"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="3" align="right"> 文章简介： </el-col>
        <el-col :span="21">
          <el-form-item prop="articleIntro">
            <el-input
              v-model="articleForm.articleIntro"
              placeholder="请输入文章简介"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="3" align="right"> 文章编码： </el-col>
        <el-col :span="21">
          <el-form-item prop="articleCode">
            <el-input
              v-model="articleForm.articleCode"
              placeholder="特殊类文章输入文章编码"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="3" align="right"> 文章分类： </el-col>
        <el-col :span="21">
          <el-form-item prop="articleCategoryId">
            <el-select
              v-model="articleForm.articleCategoryId"
              placeholder="请选择"
              class="admin_tel input77"
            >
              <el-option
                v-for="item in this.categoryList"
                :key="item.id"
                :label="item.categoryName"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="3" align="right"> 文章缩略图： </el-col>
        <el-col :span="21">
          <el-form-item prop="articleImg">
            <img
              class="articleimg"
              :src="
                articleForm.articleImg == ''
                  ? baseURL + 'Upload/img/no_data.png'
                  : baseURL + articleForm.articleImg
              "
              alt=""
            />
            <el-upload
              ref="appfile"
              drag
              class="upload-demo"
              :http-request="upload"
              action="none"
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将图片文件拖到此处，或<em>点击上传</em>
              </div>
              <div
                class="el-upload__tip"
                slot="tip"
                style="color: crimson; font-size: 15px; text-align: center"
              >
                *只能上传图片文件
              </div>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="3" align="right"> 文章内容： </el-col>
        <el-col :span="21">
          <el-form-item prop="articleContents">
            <editor
              :htmlContent="articleContents"
              :handleChange="handleChange"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="24" align="center">
          <el-button type="primary" @click.stop="saveArticle('addArticleForm')"
            >确定</el-button
          >
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import editor from "@/components/articleEditor.vue";
export default {
  components: { editor },
  data() {
    return {
      articleContents: "",
      articleForm: {
        articleTitle: "",
        articleIntro: "",
        articleContents: "",
        articleImg: "",
        articleCode: "",
      },
      baseURL: this.baseURL,
      categoryList: [],
    };
  },
  mounted() {
    this.articleContents = "<p></p>";
    this.articlelogic.categoryList().then((res) => {
      this.categoryList = res.articleCategoryList;
    });
  },
  methods: {
    handleChange: function (editor) {
      let res = editor.getHtml();
      res = res.replaceAll(this.baseURL,'/')
      this.articleForm.articleContents = res;
    },
    saveArticle: function (ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.axios
            .post(
              this.baseURL + "Article/AddArticle",
              this.articleForm,
              this.headers()
            )
            .then((res) => {
              this.resolveData(res.data, "add");
            });
        }
      });
    },
    upload: function (file) {
      let fileobj = file.file;
      let filetype = fileobj.name.substring(fileobj.name.lastIndexOf(".") + 1);
      if (
        filetype != "jpg" &&
        filetype != "jpep" &&
        filetype != "JPG" &&
        filetype != "JPEG" &&
        filetype != "png" &&
        filetype != "PNG"
      )
        this.$message({
          message: "上传文件必须为图片文件",
          type: "error",
        });
      else {
        let formdata = new FormData();
        formdata.append("file", fileobj);
        this.axios
          .post(this.baseURL + "Upload/BaseUpload", formdata, this.headers())
          .then((res) => {
            this.articleForm.articleImg = this.resolveData(res.data);
          });
      }
    },
    categoryChange(e) {
      this.articleForm.articleCategoryId = e[e.length - 1];
    },
  },
};
</script>

<style  scoped>
.list .el-row {
  margin-top: 2rem;
  line-height: 2rem;
}
.el-form >>> .el-form-item__error {
  margin-left: 0 !important;
}
.articleimg {
  width: 8rem;
  margin-right: 2rem;
}
.upload-demo {
  height: 5rem;
}
.upload-demo >>> .el-upload-dragger {
  height: 5rem !important;
}
</style>>

