<template>
  <router-view />
</template>

<style>
#App {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color:  #0e1a39;
  color: white;
}
</style>
