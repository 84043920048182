<template>
  <div class="body">
    <basecom />
    <div class="list">
      <funclist
        :tableData="this.funlogic.loadFunList()"
        :tableHeader="this.funlogic.listHeader()"
        :lazyLoad="this.funlogic.getChildFunList"
      />
    </div>
  </div>
</template>

<script>
import basecom from "@/components/base";
import funclist from "@/components/listComponents/treeList";

export default {
  components: {
    basecom,
    funclist,
  },
};
</script>




