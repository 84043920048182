<template>
  <div class="body">
    <basecom />
    <div class="list">
      <tuserLog />
    </div>
  </div>
</template>
<script>
import basecom from "@/components/base";
import tuserLog from "@/components/logComponents/tuserLog";
export default {
  components: {
    basecom,
    tuserLog,
  },
};
</script>

