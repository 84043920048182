<template>
  <div class="body">
    <basecom />

    <tuserindex />
  </div>
</template>

<script>
import basecom from "@/components/base";
import tuserindex from "@/components/tuserComponents/index";
export default {
  components: { basecom, tuserindex },
};
</script>

<style>
</style>