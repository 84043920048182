<template>
  <pagerlist
    :tableHeader="this.devicetypelogic.listHeader()"
    :tableData="deviceTypeList"
    :page="this.devicetypelogic.searchForm.page"
  />
  <pager
    :page="this.devicetypelogic.searchForm.page"
    :numTotal="numTotal"
    :handleCurrentChange="handleCurrentChange"
  />
</template>

<script>
import pagerlist from "@/components/listComponents/pagerList";
import pager from "@/components/listComponents/pager";
export default {
  components: {
    pagerlist,
    pager,
  },
  data() {
    return {
      numTotal: 0,
      deviceTypeList: [],
    };
  },
  mounted() {
    this.handleCurrentChange();
  },
  methods: {
    handleCurrentChange: function (page = 1) {
      this.devicetypelogic.searchForm.page = page;
      this.devicetypelogic.getDeviceTypeList().then((res) => {
        this.deviceTypeList = res.deviceTypeList;
        this.numTotal = res.total;
      });
    },
  },
};
</script>




