<template>
  <div class="toolBar">
    <div class="logo">
      {{ this.SystemTitle }}
    </div>
    <div class="accountName" title="个人信息">
      <el-button type="text" @click="showUser">
        当前用户:{{ this.session("username") }}</el-button
      >
    </div>
    <div class="accountForm">
      <ul>
        <h2>个人信息</h2>
        <li>
          <span>用户名：</span>
          <div>{{ this.session("username") }}</div>
        </li>
        <li>
          <span>角色：</span>
          <div>{{ this.getRoleText() }}</div>
        </li>
      </ul>
    </div>
    <div class="operate">
      <el-tooltip
        v-for="item in imgBtnList"
        effect="light"
        :content="item.functionName"
        placement="bottom"
        class="box-item"
      >
        <el-button
          type="primary"
          :key="item.id"
          @click="
            item.fAction.indexOf('.html') != -1
              ? jumpurl(item)
              : callfun(item.fAction)
          "
          :icon="
            item.fAction.indexOf('DataAnalysis') != -1
              ? url.indexOf('dataAnalysis.html') != -1
                ? item.fIcon.split(',')[0]
                : item.fIcon.split(',')[1]
              : item.fIcon
          "
          >{{ item.functionName }}</el-button
        >
      </el-tooltip>
    </div>
  </div>
  <div class="boundary">
    <img src="@/assets/img/line.png" />
  </div>
  <el-dialog
    :model-value="showEditPwd"
    center
    width="20rem"
    :before-close="noEditPwd"
  >
    <div>
      <div class="addFunc_title">修改密码</div>
      <hr />
      <div class="setpwd">
        <el-form ref="editPwdForm" :model="PwdFrom" :rules="rules">
          <el-row :gutter="24" type="flex">
            <el-col :span="24">
              <el-form-item prop="oldPwd">
                <el-input
                  v-model="PwdFrom.oldPwd"
                  placeholder="请输入原密码"
                  maxlength="30"
                  show-password
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24" type="flex">
            <el-col :span="24">
              <el-form-item prop="newPwd">
                <el-input
                  v-model="PwdFrom.newPwd"
                  placeholder="请输入新密码"
                  maxlength="30"
                  show-password
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="24" type="flex">
            <el-col :span="24">
              <el-form-item prop="newPwdAgin">
                <el-input
                  v-model="PwdFrom.newPwdAgin"
                  placeholder="请再次输入新密码"
                  maxlength="30"
                  show-password
                />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="sureBtn">
        <el-button class="qx" @click="noEditPwd()" type="info">取消</el-button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-button class="qd" @click="okEditPwd('editPwdForm')" type="primary"
          >确定</el-button
        >
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      url: window.location.href,
      imgBtnList: [],
      showEditPwd: false,
      PwdFrom: {
        oldPwd: "",
        newPwd: "",
        newPwdAgin: "",
      },
      rules: {
        oldPwd: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
        newPwd: [{ required: true, message: "请输入新密码", trigger: "blur" }],
        newPwdAgin: [
          { required: true, message: "请再次输入新密码", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.checkToken();
    this.loadFunBtnList();
  },
  methods: {
    checkToken: function () {
      let token = this.session("token");
      if (token == "" || token == null) {
        setTimeout(() => {
          this.$router.push("/login.html");
        }, 1500);
      } else
        this.axios
          .post(this.baseURL + "User/CheckToken", null, this.headers())
          .then((res) => {
            this.resolveData(res.data, "checkToken");
          });
    },
    showUser() {
      let accountForm = document.getElementsByClassName("accountForm")[0];

      if (accountForm.style.display == "block") {
        accountForm.style.display = "none";
      } else {
        accountForm.style.display = "block";
      }
    },
    loadFunBtnList() {
      let funbtnlist = JSON.parse(this.session("funbtnList"));

      if (funbtnlist != null) {
        funbtnlist = funbtnlist.filter((e) => e.isShow);

        this.imgBtnList = this.sortByKey(funbtnlist, "fOrder");
      }
    },
    jumpurl(btn) {
      let url = btn.fController + this.connector(btn) + btn.fAction;
      if (btn.fAction.indexOf(".html") > 0) {
        this.$router.push(url);
      }
    },
    connector(btn) {
      let connector = btn.fAction.indexOf(".html") > 0 ? "/" : "";
      return connector;
    },
    callfun(fun) {
      this[fun]();
    },
    logOut() {
      this.axios
        .post(this.baseURL + "User/LogOut", null, this.headers())
        .then((res) => {
          this.resolveData(res.data, "logout");
          sessionStorage.setItem("username", "");
          sessionStorage.setItem("userpassword", "");
          sessionStorage.setItem("token", "");
          sessionStorage.setItem("areaList", "");
          sessionStorage.setItem("functionList", "");
          sessionStorage.setItem("funbtnList", "");
          sessionStorage.setItem("roleList", "");
          sessionStorage.setItem("mbDhl", "");
          sessionStorage.setItem("delArr", "");
          sessionStorage.setItem("fpid", "");
          sessionStorage.setItem("fpname", "");
          sessionStorage.setItem("menuid", "");
          sessionStorage.setItem("breadcrumb", "");
        });
    },

    jumpToDataAnalysis() {
      let url = window.location.href;
      if (url.indexOf("dataAnalysis.html") != -1) {
        this.$router.push("/index.html");
      } else {
        this.$router.push("/dataAnalysis/dataAnalysis.html");
      }
    },

    editPwd() {
      this.showEditPwd = true;
    },
    noEditPwd() {
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.showEditPwd = false;
          done();
        })
        .catch((_) => {});
    },
    okEditPwd(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.axios
            .post(this.baseURL + "User/EditPwd", this.PwdFrom, this.headers())
            .then((res) => {
              this.resolveData(res.data, "edit");
            });
          this.showEditPwd = false;
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style src="@/assets/css/global.css" />
<style scoped>
.logo {
  width: 50rem;
  height: 3rem;
  line-height: 3rem;
  font-size: 2rem;
}

.operate {
  width: 15rem;
  height: 2rem;
  margin-right: 2rem;
  display: block;
  white-space: nowrap;
  margin-top: 0.5rem;
  text-align: right;
}

.boundary {
  width: 100%;
  position: absolute;
  top: 5rem;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.toolBar {
  display: flex;
  height: 6rem;
  width: 93%;
  margin-left: 4rem;
  margin-top: 1.8rem;
  justify-content: space-between;
}
.toolBar .el-button {
  padding: 0.6rem;
}
.setpwd {
  text-align: center;
  margin: 4rem 3.3rem 4rem;
}
.setpwd >>> .el-input {
  margin-bottom: 1rem;
}

.setpwd >>> .el-form-item__error {
  margin-left: 0.1rem !important;
  margin-top: 0.001rem !important;
}

.accountName {
  width: 20rem;
  height: 2rem;
  margin-top: 0.6rem;
}
.accountName .el-button {
  color: white;
  font-size: 1.3rem;
}

.accountForm {
  width: 20rem;
  height: 23rem;
  position: absolute;
  left: 65%;
  top: 4.3rem;
  color: white;
  transform: translateX(-50%);
  font-size: 1.4rem;
  z-index: 10000;
  display: none;
}
.accountForm ul {
  font-size: 1rem;
  width: 100%;
  height: 100%;
  background: url("~@/assets/img/menubg.png") no-repeat center;
  background-size: 100% 110%;
  border: 1px rgba(0, 0, 0, 0) solid;
  padding: 0;
}
.accountForm h2 {
  margin-top: 3.7rem;
  margin-left: 31%;
  position: relative;
  margin-bottom: 2rem;
}
.accountForm li:nth-child(1) {
  margin-top: 2rem;
}
.accountForm li {
  list-style: none;
  margin-left: 2.5rem;
  text-align: left;
  width: 70%;
  height: 3.5rem;
  white-space: nowrap;
  border: 1px solid;
}
.accountForm li div {
  display: inline-block;
  width: 7rem;
  height: 2.5rem;
  margin-top: 1rem;
  white-space: nowrap;
  overflow-x: auto;
  float: right;
}
.accountForm li span {
  line-height: 3.5rem;
  margin-left: 2rem;
}
</style>
