<template>
  <div class="search">
    <el-row :gutter="10" type="flex" align="middle">
      <el-col :span="3.5">
        <div class="listTitle">
          文章列表·共<span style="color: coral">{{ numTotal }}</span
          >条
        </div>
      </el-col>
      <el-col :span="3.5">
        <el-input
          v-model="searchVal"
          placeholder="请输入标题/简介"
          @change="setSearchVal"
          prefix-icon="search"
          @keyup.enter.native="search"
        >
        </el-input>
      </el-col>

      <el-col :span="1.5">
        <el-button @click="search" type="primary">查询</el-button>
      </el-col>
    </el-row>
    <el-row v-if="delBtn">
      <el-col :span="24" align="left">
        <el-button @click="delAll" type="danger">删除</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      delBtn: false,
      searchVal: "",
    };
  },
  props: {
    numTotal: {
      type: Number,
      default: 0,
    },
    search: {
      type: Function,
      default: function (page, searchVal) {},
    },
    delAll: {
      type: Function,
      default: function () {},
    },
  },
  mounted() {
    this.load();
  },

  methods: {
    load() {
      let href = window.location.href;
      if (href.indexOf("del") > 0) {
        this.delBtn = true;
      }
      this.articlelogic.searchObj.searchVal = "";
    },
    setSearchVal() {
      this.articlelogic.searchObj.searchVal = this.searchVal;
      let interval = setInterval(() => {
        if (this.articlelogic.searchObj.searchVal === "") {
          this.searchVal = "";
          clearInterval(interval);
        }
      }, 500);
    },
  },
};
</script>


