<template>
  <div class="body">
    <basecom />

    <testIndex />
  </div>
</template>

<script>
import basecom from "@/components/base";
import testIndex from "@/components/testComponents/index";
export default {
  components: { basecom, testIndex },
};
</script>

<style>
</style>