
<template>
  <div>
    <roleList
      :tableData="this.rolelogic.roleList()"
      :tableHeader="this.rolelogic.opHeader()"
      :lazyLoad="this.rolelogic.getChildRoleList"
    >
      <template v-slot:btn="slotProps">
        <el-button type="primary" @click.stop="addrole(slotProps.row)">
          添加子角色
        </el-button>
      </template>
    </roleList>

    <el-dialog
      :model-value="addRoleDial"
      width="35rem"
      center
      :before-close="nosureAddRole"
    >
      <div class="addFunc_title">添加子角色</div>
      <hr />
      <div class="xgmm_form">
        <el-form
          ref="addroleForm"
          :model="roleForm"
          :rules="this.rolelogic.rules"
        >
          <el-row
            style="display: flex; flex-wrap: wrap; justify-content: center"
          >
            <el-col :span="18">
              <el-form-item prop="roleName">
                <div>
                  <span><i>*</i>角色名称：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="roleForm.roleName"
                    maxlength="30"
                    placeholder="请输入角色名称"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="sureBtn">
        <el-button class="qx" @click="nosureAddRole('addroleForm')" type="info"
          >取消</el-button
        >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-button class="qd" @click="sureAddRole('addroleForm')" type="primary"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>


<script>
import roleList from "@/components/listComponents/treeList";
export default {
  name: "add",
  components: {
    roleList,
  },
  data() {
    return {
      addRoleDial: false,
      roleName: "",
      roleForm: {},
      roleObj: {},
    };
  },
  methods: {
    sureAddRole(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.roleForm.rpid = this.roleObj.id;
          this.axios
            .post(this.baseURL + "Role/RoleAdd", this.roleForm, this.headers())
            .then((res) => {
              this.resolveData(res.data, "add");
              this.addRoleDial = false;
            });
        } else {
          return false;
        }
      });
    },
    nosureAddRole(ref) {
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.addRoleDial = false;
          done();
        })
        .catch((_) => {});
    },

    addrole(row) {
      this.roleObj = row;
      this.addRoleDial = true;
      this.resetForm("addroleForm");
    },
    resetForm(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
    },
  },
};
</script>