<template>
  <div class="body">
    <basecom />
    <sysStatus/>
  </div>
  
</template>

<script>
import basecom from "@/components/base.vue";
import sysStatus from "@/components/sysStatus.vue"

export default {
  components: { basecom, sysStatus},
};
</script>




