import proxy from '../main'
export default {
    searchObj: {
        page: 1,
        Id: "00000000-0000-0000-0000-000000000000",
        searchVal: "",
    },
    categorySearchObj: {
        page: 1,
    },
    rules: {
        articleTitle: [{ required: true, message: "请输入文章标题", trigger: "blur" },],
        articleIntro: [{ required: true, message: "请输入文章简介", trigger: "blur" },],
    },
    isShow: [
        { value: false, label: "否" },
        { value: true, label: "是" },
    ],

    listHeader: function () {
        return [
            { type: "index", label: "序号", width: "100" },
            { prop: "articleTitle", label: "文章标题", width: "600" },
            { prop: "readCount", label: "阅读量" },
            { prop: "createDate", label: "添加时间", width: "200" },

        ];
    },
    opHeader: function () {
        let header = this.listHeader();
        header.push({ prop: "btn", slot: "btn", label: "操作" });
        return header;
    },
    categoryListHeader: function () {
        return [
            { type: "index", label: "序号", width: "100" },
            { prop: "categoryName", label: "分类名称", width: "600" },
            { prop: "categoryCode", label: "分类编码" },

        ];
    },
    categoryOpHeader: function () {
        let header = this.categoryListHeader();
        header.push({ prop: "btn", slot: "btn", label: "操作" });
        return header;
    },
    articleList: function () {
        return new Promise((resolve) => {

            proxy.axios.post(proxy.baseURL + "Article/ArticleList", this.searchObj, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data));
                })
        });

    },
    categoryList: function () {
        return new Promise((resolve) => {
            proxy.axios.post(proxy.baseURL + "Article/ArticleCategoryList", this.categorySearchObj, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data));
                })
        });
    }
}