
<script>
import { onBeforeUnmount, ref } from "vue";
import {
  Editor,
  Toolbar,
  getEditor,
  removeEditor,
} from "@wangeditor/editor-for-vue";
import proxy from "../main";
export default {
  components: { Editor, Toolbar },
  setup() {
    const editorId = `w-e-${Math.random().toString().slice(-5)}`;

    let uploadConfig = {
      maxFileSize: proxy.maxFileSize,
      customUpload: function (file, insertFn) {
        if (file.size > proxy.maxFileSize)
          proxy.$message({
            message: "文件超过上传限制，最大25M",
            type: "error",
          });
        else {
          let forms = new FormData();
          var headers = {
            headers: {
              "Content-Type": "multpart/form-data",
              token: proxy.token(),
            },
          };
          forms.append("file", file);
          proxy.axios
            .post(proxy.baseURL + "Upload/WangEditorUpload", forms, headers)
            .then((res) => {
              let d = proxy.resolveData(res.data);
              insertFn(proxy.baseURL + d.data.url);
            });
        }
      },
      onError(file, err, res) {
        proxy.$message({
          message: err,
          type: "error",
        });
      },
    };
    const toolbarConfig = {};
    const editorConfig = {
      placeholder: "请输入内容...",
      MENU_CONF: {
        uploadImage: uploadConfig,
        uploadVideo: uploadConfig,
      },
    };
    const flag = ref(false);
    onBeforeUnmount(() => {
      const editor = getEditor(editorId);
      if (editor == null) return;
      editor.destroy();
      removeEditor(editorId);
    });
    const handleCreated = (editor) => {
      console.log(editor.getMenuConfig("uploadImage"));
    };

    return {
      editorId,
      mode: "default",
      toolbarConfig,
      editorConfig,
      flag,
      handleCreated,
    };
  },
  props: {
    handleChange: {
      type: Function,
      default: function () {},
    },
    htmlContent: {
      type: String,
      default: "",
    },
  },
  watch: {
    htmlContent(val) {
      this.flag = true;
      console.log(val);
      const editor = getEditor(this.editorId);
      if (editor == null) return;
      editor.clear();
      editor.deleteFragment();
      editor.dangerouslyInsertHtml(val);
    },
  },
};
</script>
<template>
  <div style="border: 1px solid #ccc" v-if="flag">
    <!-- 工具栏 -->
    <Toolbar
      :editorId="editorId"
      :defaultConfig="toolbarConfig"
      style="border-bottom: 1px solid #ccc"
    />
    <!-- 编辑器 -->
    <Editor
      :editorId="editorId"
      :defaultConfig="editorConfig"
      :defaultHtml="htmlContent"
      @onChange="handleChange"
      @onCreated="handleCreated"
      style="height: 20rem; overflow-y: hidden"
    />
  </div>
</template>
<style src="@wangeditor/editor/dist/css/style.css" />
