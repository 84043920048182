<template>
  <div class="body">
    <basecom />

    <div class="list">
      <edit />
    </div>
  </div>
</template>

<script>
import basecom from "@/components/base";
import edit from "@/components/deviceTypeComponents/edit";
export default {
  components: { basecom, edit },
};
</script>

<style>
</style>