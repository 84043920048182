<template>
  <div class="list">
    <tchildSearch :numTotal="childTotal" :search="search" />
    <pagerlist
      :tableHeader="this.childlogic.childListHeader()"
      :page="this.childlogic.pageObj.page"
      :tableData="childList"
    />
    <pager
      :handleCurrentChange="allChildList"
      :page="this.childlogic.pageObj.page"
      :numTotal="childTotal"
    />
  </div>
</template>

<script>
import tchildSearch from "@/components/searchComponents/tchildSearch.vue";
import pagerlist from "@/components/listComponents/pagerList.vue";
import pager from "@/components/listComponents/pager.vue";
export default {
  components: { pagerlist, pager, tchildSearch },
  data() {
    return {
      tuserList: [],
      childPage: 1,
      childTotal: 0,
      tuserTotal: 0,
      showDialog: false,
      childList: [],
    };
  },
  mounted() {
    this.allChildList();
  },
  methods: {
    search: function (val) {
      if (val == "") {
        this.allChildList();
      } else {
        this.childlogic.childList(val).then((res) => {
          this.childList = res.tChildList;
          this.childTotal = res.total;
        });
      }
    },
    allChildList: function (page = 1) {
      this.childlogic.pageObj.page = page;

      this.childlogic.allChildList().then((res) => {
        this.childList = res.tChildList;
        this.childTotal = res.total;
      });
    },
  },
};
</script>

<style>
</style>