import proxy from '../main'
export default {
    searchObj: { page: 1, childId: "" },
    searchObjP: { childId: "", echartsType: 1 },
    childListOpHeader: function () {
        let header = proxy.childlogic.childListHeader();
        header.push({ prop: "btn", slot: "btn", label: "操作" });
        return header;
    },
    testLogHeader: function () {
        return [
            { type: "index", label: "序号", width: "100" },
            { prop: "recordValue", label: "测量值" },
            { prop: "contrastValue", label: "对照值" },
            { prop: "testTypeText", label: "测量类型" },
            { prop: "activateStatusText", label: "数据状态", width: "200" },
            { prop: "recordDate", label: "测量时间" },
        ];
    },
    testLogOpHeader: function () {
        let header = this.testLogHeader();
        header.push({ prop: "btn", slot: "btn", label: "操作", width: "350" });
        return header;
    },

    testLogList: function () {
        return new Promise((resolve) => {

            proxy.axios.post(proxy.baseURL + "Record/RecordList", this.searchObj, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data));
                })
        });
    },
    testLogPList: function () {
        return new Promise((resolve) => {

            proxy.axios.post(proxy.baseURL + "Record/RecordListP", this.searchObjP, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data));
                })
        });
    }
}