import proxy from '../main'
export default {
    searchForm: {
        page: 1
    },
    rules: {
        testPText: [{ required: true, message: "请输入测量部位名称", trigger: "blur" },],
        testPCode: [{ required: true, message: "请输入测量部位编码", trigger: "blur" },]
    },
    listHeader: function () {
        return [
            { type: "index", label: "序号", width: "100" },
            { prop: "testPText", label: "测量部位名称" },
            { prop: "testPCode", label: "测量部位编码" },
        ];
    },
    opHeader: function () {
        let header = this.listHeader();
        header.push({ prop: "btn", slot: "btn", label: "操作" });
        return header;
    },
    getTestPositionList: function () {
        return new Promise((resolve) => {

            proxy.axios.post(proxy.baseURL + "Base/TestPositionList", this.searchForm, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data));
                })
        });
    }

}
