<template>
  <div class="body">
    <basecom />

    <childIndex />
  </div>
</template>

<script>
import basecom from "@/components/base";
import childIndex from "@/components/childComponents/index";
export default {
  components: { basecom, childIndex },
};
</script>

<style>
</style>