 <template>
  <el-table
    :data="tableData"
    ref="listTable"
    width="100%"
    align="center"
    row-key="id"
    @selection-change="handleSelection"
    @row-dblclick="dbClick"
    @row-click="rowClick"
  >
    <el-table-column
      v-if="needselect"
      type="selection"
      width="80"
      align="center"
    />

    <el-table-column v-if="needradio" type="radio" width="50" align="center" />

    <el-table-column
      v-for="(th, key) in tableHeader"
      :type="th.type"
      :key="key"
      :prop="th.prop"
      :label="th.label"
      min-width="50"
      :width="th.width"
      :align="th.align == null ? 'center' : th.align"
    >
      <template v-slot="scope">
        <span v-if="th.type == 'index'">{{
          (page - 1) * pageSize + scope.$index + 1
        }}</span>

        <slot
          :name="th.prop"
          v-else-if="th.slot"
          :row="scope.row"
          :index="scope.$index"
        ></slot>
        <!-- <span v-else-if="!scope.row[th.prop]"> - </span>-->
        <span v-else>
          {{
            scope.row[th.prop] == "2000-01-01 00:00:00"
              ? "-"
              : scope.row[th.prop]
          }}</span
        >
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  name: "pagerList",
  components: {},
  props: {
    tableData: {
      type: Array,
      default: [],
    },
    tableHeader: {
      type: Array,
      default: [],
    },
    page: {
      type: Number,
      default: 1,
    },
    needselect: {
      type: Boolean,
      default: false,
    },
    needradio: {
      type: Boolean,
      default: false,
    },
    handleSelection: {
      type: Function,
      default: function () {},
    },
    dbClick: {
      type: Function,
      default: function () {},
    },
    rowClick: {
      type: Function,
      default: function () {},
    },
  },
  data() {
    return {
      tableHeight: "45rem",
      pageSize: this.PageSize,
    };
  },
};
</script>

<style scoped>
</style>