<template>
  <div class="body">
    <basecom />

    <allTest />
  </div>
</template>

<script>
import basecom from "@/components/base";
import allTest from "@/components/testComponents/all";
export default {
  components: { basecom, allTest },
};
</script>

<style>
</style>