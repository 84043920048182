<template>
  <div class="list">
    <div class="addFunc_title">添加文章分类</div>
    <hr />
    <el-form
      ref="addForm"
      :model="categoryForm"
      :rules="this.articlelogic.categoryRules"
    >
      <el-row :gutter="20">
        <el-col :span="10" align="right"> 文章分类名称： </el-col>
        <el-col :span="14">
          <el-form-item prop="categoryName">
            <el-input
              v-model="categoryForm.categoryName"
              placeholder="请输入文章分类名称"
            />
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="10" align="right"> 文章分类编码： </el-col>
        <el-col :span="14">
          <el-form-item prop="categoryCode">
            <el-input
              v-model="categoryForm.categoryCode"
              placeholder="请输入文章分类编码"
            />
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="10" align="right"> 是否显示： </el-col>
        <el-col :span="14">
          <el-form-item prop="isShow">
            <el-select
              v-model="categoryForm.isShow"
              placeholder="请选择"
              class="admin_tel input77"
            >
              <el-option
                v-for="item in this.articlelogic.isShow"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="24" align="center">
          <el-button type="primary" @click.stop="saveCategory('addForm')"
            >确定</el-button
          >
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      categoryForm: {
        categoryName: "",
        categoryCode: "",
        isShow: false,
      },
      baseURL: this.baseURL,
    };
  },
  methods: {
    saveCategory: function (ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.axios
            .post(
              this.baseURL + "Article/AddArticleCategory",
              this.categoryForm,
              this.headers()
            )
            .then((res) => {
              this.resolveData(res.data, "add");
            });
        }
      });
    },
  },
};
</script>

<style  scoped>
.list .el-row {
  margin-top: 2rem;
  line-height: 2rem;
}
.el-form >>> .el-form-item__error {
  margin-left: 0 !important;
}
.el-input {
  max-width: 20rem;
}
</style>>

