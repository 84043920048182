<template>
  <div class="list">
    <div class="addFunc_title">APP 版本管理</div>
    <hr />

    <el-row :gutter="20">
      <el-col :span="10" align="right"> 版本号： </el-col>
      <el-col :span="14">{{ this.oldversion.version }} </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="10" align="right"> 下载地址： </el-col>
      <el-col :span="14">{{ this.oldversion.downloadUrl }} </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24" align="center">
        <el-button type="primary" @click.stop="uploadNewVersion"
          >提交新版本</el-button
        >
      </el-col>
    </el-row>
    <el-dialog
      :model-value="showDialog"
      width="30rem"
      center
      :before-close="closeShowDial"
    >
      <div class="addFunc_title">提交新版本</div>
      <hr />
      <el-upload
        ref="appfile"
        drag
        class="upload-demo"
        :http-request="upload"
        action="none"
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          将APK文件拖到此处，或<em>点击上传</em>
        </div>
        <div
          class="el-upload__tip"
          slot="tip"
          style="color: crimson; font-size: 15px; text-align: center"
        >
          *只能上传APK文件
        </div>
      </el-upload>
      <el-form ref="addForm" :model="modelForm" :rules="this.rules">
        <el-row :gutter="20">
          <el-col :span="6" align="right"> 版本号： </el-col>
          <el-col :span="14">
            <el-form-item prop="version"
              ><el-input
                v-model="modelForm.version"
                placeholder="请输入新版本号"
            /></el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="6" align="right"> 下载地址： </el-col>
          <el-col :span="14"
            ><el-form-item prop="downloadUrl">
              <el-input
                v-model="modelForm.downloadUrl"
                disabled
                placeholder="上传APK文件后，将读取新的下载地址"
                :autosize="{ minRows: 1, maxRows: 6 }"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24" align="center">
            <el-button type="primary" @click.stop="saveNewVersion('addForm')"
              >确定</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      showDialog: false,
      oldversion: { version: "", downloadUrl: "" },

      rules: {
        version: [
          { required: true, message: "请输入新版本号", trigger: "blur" },
        ],
        downloadUrl: [
          { required: true, message: "请上传APK文件", trigger: "blur" },
        ],
      },
      modelForm: {
        version: "",
        downloadUrl: "",
      },
    };
  },
  mounted() {
    this.axios
      .get(this.baseURL + "AppVersion/NewVersion", this.headers())
      .then((res) => {
        this.oldversion = this.resolveData(res.data);
      });
  },
  methods: {
    closeShowDial(ref) {
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.showDialog = false;
          done();
        })
        .catch((_) => {});
    },
    uploadNewVersion: function () {
      this.showDialog = true;
    },
    upload(file) {
      let fileobj = file.file;
      let filetype = fileobj.name.substring(fileobj.name.lastIndexOf(".") + 1);
      if (filetype != "apk")
        this.$message({
          message: "上传文件必须为APK文件",
          type: "error",
        });
      else {
        let formdata = new FormData();
        formdata.append("file", fileobj);
        this.axios
          .post(
            this.baseURL + "AppVersion/UploadAppFile",
            formdata,
            this.headers()
          )
          .then((res) => {
            this.modelForm.downloadUrl = this.resolveData(res.data);
          });
      }
    },
    saveNewVersion: function (ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.axios
            .post(
              this.baseURL + "AppVersion/EditVersion",
              this.modelForm,
              this.headers()
            )
            .then((res) => {
              this.resolveData(res.data, "edit");
            });
        }
      });
    },
  },
};
</script>

<style  scoped>
.el-row {
  line-height: 2rem;
  color: white;
  margin-bottom: 2rem;
}
.upload-demo {
  margin-left: 0;
  margin-right: 0;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center !important;
}
.el-upload__text {
  margin-top: 20%;
}
.el-form >>> .el-form-item__error {
  margin-left: 0 !important;
}
</style>>

