<template>
  <div class="body">
    <basecom />
    <div class="list">
      <roleList
        :tableData="this.rolelogic.roleList()"
        :tableHeader="this.rolelogic.listHeader()"
        :lazyLoad="this.rolelogic.getChildRoleList"
      />
    </div>
  </div>
</template>
<script>
import basecom from "@/components/base";
import roleList from "@/components/listComponents/treeList";
export default {  
  components: {
    basecom,
    roleList,
  },
};
</script>


