<template>
  <div class="body">
    <basecom></basecom>
    <div class="list">
    <assignRole></assignRole>
    </div>
  </div>
</template>

<script>
import basecom from "@/components/base";
import assignRole from "@/components/userComponents/assignRole";

export default {
  name: "assign",
  components: {
    basecom,
    assignRole,
  },
};
</script>

