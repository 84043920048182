<template>
  <div class="list">
    <pagerlist
      :tableHeader="this.articlelogic.categoryListHeader()"
      :tableData="categoryList"
    />
    <pager
      :handlerCurrentPage="handlerCurrentPage"
      :page="page"
      :numTotal="numTotal"
    />
  </div>
</template>

<script>
import pagerlist from "@/components/listComponents/pagerList.vue";
import pager from "@/components/listComponents/pager.vue";

export default {
  components: { pagerlist, pager },
  data() {
    return {
      categoryList: [],
      page: 1,
      numTotal: 0,
    };
  },
  mounted() {
    this.handlerCurrentPage();
  },
  methods: {
    handlerCurrentPage: function (page = 1) {
      this.articlelogic.categorySearchObj.page = page;
      this.articlelogic.categoryList().then((res) => {
        this.categoryList = res.articleCategoryList;
        this.numTotal = res.total;
      });
    },
  },
};
</script>

<style>
</style>