<template>
  <el-container>
    <el-header />
    <el-main>
      <div class="login">
        <p>登 录</p>

        <el-tabs type="border-card" @tab-click="handleTabsEdit">
          <el-tab-pane label="账号密码登录">
            <el-form
              ref="userLoginform"
              :rules="userLoginRules"
              :model="userLoginForm"
            >
              <el-form-item prop="username">
                <el-input
                  v-model.trim="userLoginForm.username"
                  prefix-icon="user"
                  placeholder="用户名/手机号/邮箱/身份证号"
                />
              </el-form-item>
              <el-form-item prop="password">
                <el-input
                  type="password"
                  show-password
                  v-model.trim="userLoginForm.password"
                  prefix-icon="lock"
                  placeholder="请输入密码"
                />
              </el-form-item>
              <el-form-item prop="verifyNum">
                <el-row :gutter="10">
                  <el-col :span="7"> 验证码： </el-col>
                  <el-col :span="10"><signcode /> </el-col>
                  <el-col :span="7">
                    <el-input
                      maxlength="4"
                      v-model="userLoginForm.verifyNum"
                      @keyup.enter.native="login()"
                    ></el-input>
                  </el-col>
                </el-row>
              </el-form-item>
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="短信验证码登录">
            <el-form
              ref="phoneLoginform"
              :rules="phoneLoginRules"
              :model="phoneLoginForm"
            >
              <el-form-item prop="phone">
                <el-input
                  v-model.trim="phoneLoginForm.phone"
                  placeholder="请输入手机号"
                  onkeyup="this.value = this.value.replace(/[^\d]/g,'');"
                  prefix-icon="iphone"
                />
              </el-form-item>
              <el-form-item prop="signcode">
                <el-input
                  placeholder="请输入验证码"
                  v-model.trim="phoneLoginForm.signcode"
                  @keyup.enter.native="login()"
                  prefix-icon="editPen"
                />
              </el-form-item>
              <el-button :type="btnType" @click="getCode()" :disabled="noGet">
                <span class="countdown">{{ countdown }}秒后可再次</span>
                获取验证码
              </el-button>
            </el-form>
          </el-tab-pane>
          <el-row>
            <el-col> </el-col>
          </el-row>
        </el-tabs>
        <el-button
          type="primary"
          @click="login()"
          style="margin-top: 2rem; width: 100%"
        >
          登 &nbsp;&nbsp;&nbsp;&nbsp;录
        </el-button>
      </div>
    </el-main>
    <el-footer height="30%" />
  </el-container>
</template>

<script >
import signcode from "@/components/signCode.vue";
export default {
  name: "Login",
  components: {
    signcode,
  },
  data() {
    return {
      loginType: "0",
      btnType: "warning",
      noGet: false,
      countdown: 60,
      userLoginForm: { username: "", password: "", verifyNum: "" },
      phoneLoginForm: { phone: "", signcode: "" },
      userLoginRules: {
        username: [
          {
            required: true,
            message: "请输入用户名/手机号/身份证号/邮箱!",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码!",
            trigger: "blur",
          },
        ],
        verifyNum: [
          {
            required: true,
            validator: this.checkCode,
            trigger: "blur",
          },
        ],
      },
      phoneLoginRules: {
        phone: [
          { required: true, validator: this.checkPhone, trigger: "blur" },
        ],
        signcode: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
    };
  },

  methods: {
    checkPhone: function (rule, value, callback) {
      if (value === "") {
        callback(new Error("请输入手机号"));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    },
    checkCode: function (rule, value, callback) {
      if (value === "") {
        callback(new Error(" "));
      } else if (value !== this.loginLogic.identCode) {
        callback(new Error(" "));
      } else {
        callback();
      }
    },

    handleTabsEdit: function (obj) {
      this.loginType = obj.index;
    },
    getCode: function () {
      this.$refs["phoneLoginform"].validateField("phone", (valid) => {
        if (!valid) {
          console.log(this.baseURL);

          this.axios
            .get(
              this.baseURL +
                "User/SendSMSCode?phone=" +
                this.phoneLoginForm.phone
            )
            .then((res) => {
              let countdown = document.querySelector(".countdown");
              countdown.style.display = "inline";
              this.btnType = "info";
              this.countdown = 60;
              let timer = setInterval(() => {
                this.noGet = true;
                this.countdown -= 1;
                if (this.countdown === 0) {
                  this.noGet = false;
                  this.countdown = "";
                  countdown.style.display = "none";
                  this.btnType = "warning";
                  clearInterval(timer);
                }
              }, 1000);
            });
        }
      });
    },

    login: function () {
      let aa;
      let url;
      let ruleChecked;
      if (this.loginType === "0") {
        this.$refs["userLoginform"].validate((valid) => {
          if (valid) {
            url = "User/UserLogin";
            aa = {
              userName: this.userLoginForm.username,
              pwd: this.userLoginForm.password,
            };
            ruleChecked = valid;
          }
        });
      }
      if (this.loginType === "1") {
        this.$refs["phoneLoginform"].validate((valid) => {
          if (valid) {
            url = "User/UserLoginWithCheckCode";
            aa = {
              phone: this.phoneLoginForm.phone,
              code: this.phoneLoginForm.signcode,
            };
            ruleChecked = valid;
          }
        });
      }

      if (ruleChecked) {
        this.axios.post(this.baseURL + url, aa).then((response) => {
          let data = this.resolveData(response.data);

          let funlist = [];
          let funbtnlist = [];
          data.functionList.forEach((fun) => {
            if (fun.functionTypeCode == 1) {
              funbtnlist.push(fun);
            } else if (fun.functionTypeCode == 2) {
              funlist.push(fun);
            }
          });

          sessionStorage.setItem("mbDhl", "");
          sessionStorage.setItem("delArr", "");
          sessionStorage.setItem("fpid", "");
          sessionStorage.setItem("fpname", "");
          sessionStorage.setItem("menuid", "");

          sessionStorage.setItem("username", data.userName);
          sessionStorage.setItem("token", data.token);
          sessionStorage.setItem("areaList", JSON.stringify(data.areaList));
          sessionStorage.setItem("functionList", JSON.stringify(funlist));
          sessionStorage.setItem("funbtnList", JSON.stringify(funbtnlist));
          sessionStorage.setItem("roleList", JSON.stringify(data.roleList));
          this.$message({
            message: `登录成功`,
            type: "success",
          });
          this.$router.push("/index.html");
        });
      }
    },
  },
};
</script>
<style scoped>
.el-header {
  height: 5rem;
}
.el-main {
  margin: auto;
}
.el-footer {
  height: 100%;
}
.login {
  background-color: white;
  color: black;
  padding: 3rem;
}
.login p {
  margin-top: 0;
  font-size: 2rem;
  font-weight: 800;
  text-align: center;
}

.el-tabs {
  max-width: 16.5rem;
}
.el-row {
  line-height: 2.5rem;
  text-align: right;
}
.el-form-item {
  height: 3rem;
}
.el-form-item >>> .el-form-item__error {
  margin: 0 !important;
  padding: 0 !important;
}
.countdown {
  display: none;
}
</style>