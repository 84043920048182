<template>
  <div class="body">
    <basecom></basecom>
    <div class="list">
      <edit></edit>
    </div>
  </div>
  
</template>

<script>
import basecom from "@/components/base";
import edit from "@/components/userComponents/edit.vue";

export default {
  name: "addUser",
  components: {
    basecom,
    edit,
  },
};
</script>
