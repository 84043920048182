<template>
  <div class="list">
    <articleSearch :numTotal="numTotal" :search="search" :delAll="delAll" />
    <pagerlist
      :tableHeader="this.articlelogic.listHeader()"
      :tableData="articleList"
      :needselect="true"
      :handleSelection="handleSelection"
    />
    <pager
      :handlerCurrentPage="handlerCurrentPage"
      :page="page"
      :numTotal="numTotal"
    />
  </div>
</template>

<script>
import articleSearch from "@/components/searchComponents/articleSearch.vue";
import pagerlist from "@/components/listComponents/pagerList.vue";
import pager from "@/components/listComponents/pager.vue";

export default {
  components: { pagerlist, pager, articleSearch },
  data() {
    return {
      articleList: [],
      page: 1,
      numTotal: 0,
      selectedItems: [],
    };
  },
  mounted() {
    this.handlerCurrentPage();
  },
  methods: {
    handlerCurrentPage: function (page = 1) {
      this.articlelogic.searchObj.page = page;
      this.articlelogic.articleList().then((res) => {
        this.articleList = res.articleList;
        this.numTotal = res.total;
      });
    },
    search: function () {
      this.handlerCurrentPage();
    },
    handleSelection: function (val) {
      this.selectedItems = val;
    },
    delAll: function () {
      this.$confirm("确认要删除选中的文章吗？")
        .then((_) => {
          this.$confirm("请进行再次确认删除！")
            .then((_) => {
              let aa = { idList: [] };
              this.selectedItems.forEach((item) => {
                aa.idList.push(item.id);
              });

              this.axios({
                method: "delete",
                url: this.baseURL + "Article/DeleteArticle",
                data: aa,
                headers: this.header(),
              }).then((res) => {
                this.resolveData(res.data, "delete");
              });
            })
            .catch((_) => {});
        })
        .catch((_) => {});
    },
  },
};
</script>

<style>
</style>