<template>
  <div class="body">
    <basecom />
    <div class="list">
      <sysLog />
    </div>
  </div>
</template>
<script>
import basecom from "@/components/base";
import sysLog from "@/components/logComponents/sysLog";
export default {
  components: {
    basecom,
    sysLog,
  },
};
</script>

