<template>
  <div class="body">
    <basecom />

    <articleindex />
  </div>
</template>

<script>
import articleindex from "@/components/articleComponents/index";
import basecom from "@/components/base";
export default {
  components: { basecom, articleindex },
};
</script>

<style>
</style>