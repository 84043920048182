import * as Vue from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import * as ElIcons from '@element-plus/icons-vue'
import axios from 'axios';
import VueAxios from 'vue-axios';
import App from './App.vue'
import router from './router'
import loginLogic from '@/logic/loginlogic'
import funlogic from '@/logic/funlogic'
import rolelogic from '@/logic/rolelogic'
import userlogic from '@/logic/userlogic'
import tuserlogic from '@/logic/tuserlogic'
import articlelogic from '@/logic/articlelogic'
import childlogic from '@/logic/childlogic'
import testlogic from "@/logic/testlogic"
import testpositionlogic from "@/logic/testpositionlogic"
import devicetypelogic from "@/logic/devicetypelogic"


const app = Vue.createApp(App)
app.use(VueAxios, axios);
app.use(ElementPlus, {
    locale: zhCn,
})
for (const name in ElIcons) {
    app.component(name, ElIcons[name])
}
app.use(router)
app.config.globalProperties.SystemTitle = "MBJ30 经皮黄疸仪后台管理系统"
app.config.globalProperties.SystemShortTitle = "黄疸仪后台系统"

app.config.globalProperties.loginLogic = loginLogic;
app.config.globalProperties.funlogic = funlogic;
app.config.globalProperties.rolelogic = rolelogic;
app.config.globalProperties.userlogic = userlogic;
app.config.globalProperties.tuserlogic = tuserlogic;
app.config.globalProperties.articlelogic = articlelogic;
app.config.globalProperties.childlogic = childlogic;
app.config.globalProperties.testlogic = testlogic;
app.config.globalProperties.testpositionlogic = testpositionlogic;
app.config.globalProperties.devicetypelogic = devicetypelogic;

app.config.globalProperties.baseURL = "http://jaundiceapi.mbelec.com/";
//app.config.globalProperties.baseURL = "http://192.168.0.168:82/";
app.config.globalProperties.emptyId = "00000000-0000-0000-0000-000000000000";
app.config.globalProperties.needLogin = false;
app.config.globalProperties.PageSize = 10;

app.config.globalProperties.maxFileSize = 25 * 1024 * 1024;
app.config.globalProperties.token = function () { return sessionStorage.getItem("token") };
app.config.globalProperties.header = function () { return { token: this.token() }; };
app.config.globalProperties.headers = function () { return { headers: this.header() }; };
app.config.globalProperties.session = function (item) { return sessionStorage.getItem(item); }


app.config.globalProperties.getFunction = function () {
    let fl = JSON.parse(this.session("functionList"))
    let fo = fl.filter(element => element.fpid === this.emptyId);
    if (fo != null) {

        fl = fo
    } else {
        fl = this.functiontoTree(fl)
    }
    return fl
}
app.config.globalProperties.getRole = function () {

    let rlstr = this.session("roleList")
    if (rlstr != null && rlstr != undefined) {
        let rl = JSON.parse(rlstr)
        let ro = rl.find(element => element.rpid === this.emptyId);
        if (ro != null) {
            rl = []
            rl.push(ro)
        } else {
            rl = this.roletoTree(rl)

        }
        return rl
    } else
        return null

}
app.config.globalProperties.getRoleText = function () {
    let rt = "";
    let rl = this.getRole();
    if (rl != null) {
        rl.forEach(item => { rt += item.roleName });
    }
    return rt
}
app.config.globalProperties.sortByKey = function (array, key) {
    return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x < y ? 1 : 0;
    });
};
app.config.globalProperties.resolveData = function (res, option = "data") {
    if (res.code === 103 || res.code === -1) {
        if (this.needLogin == false) this.needLogin = true;
        if (this.needLogin) {
            this.$message({
                message: res.msg,
                type: "error",
            });
            setTimeout(() => {
                this.needLogin = false;
                this.$router.push("/login.html");
            }, 1500);
        }
    } else {
        if (res.code === 200) {
            let msg = "";
            switch (option) {
                case "add":
                    msg = "添加成功";
                    break;
                case "edit":
                    msg = "修改成功";
                    break;

                case "assign":
                    msg = "分配成功";
                    break;
                case "delete":
                    msg = "删除成功";
                    break;
                case "logout":
                    msg = "退出成功";
                    break;
            }
            if (msg != "") {
                this.$message({
                    message: msg,
                    type: "success",
                });
                if (msg == "退出成功") {
                    setTimeout(() => {
                        this.$router.push("/login.html");
                    }, 1500);
                } else {
                    setTimeout(() => {
                        window.location.reload();
                    }, 500);
                }
            }
            return res.data;
        } else {
            this.$message({
                message: res.msg,
                type: "error",
            });
            return null;
        }
    }
};
router.beforeEach((to, from, next) => {
    next();
});





let reqList = []

function stopRepeatRequest(reqList, url, cancel, errorMessage) {
    const errorMsg = errorMessage || ''
    for (let i = 0; i < reqList.length; i++) {
        if (reqList[i] === url) {
            cancel(errorMsg)
            return
        }
    }
    reqList.push(url)
}
function allowRequest(reqList, url) {
    for (let i = 0; i < reqList.length; i++) {
        if (reqList[i] === url) {
            reqList.splice(i, 1)
            break
        }
    }
}

axios.interceptors.request.use((config) => {
    showFullScreenLoading()
    let cancel
    config.cancelToken = new axios.CancelToken(c => cancel = c)
    stopRepeatRequest(reqList, config.url, cancel, `${config.url} 请求被中断`)
    return Promise.resolve(config)
}, (error) => {
    return Promise.reject(error)
});
axios.interceptors.response.use((res) => {
    return new Promise(resolve => setTimeout(() => {
        tryHideFullScreenLoading()
        allowRequest(reqList, res.config.url)

        resolve(res)
    }, 500))

}, (error) => {

    if (axios.isCancel(error)) {

    } else {
        allowRequest(reqList, error.config.url)
    }
    return Promise.reject(error)
})

let needLoadingRequestCount = 0

function showFullScreenLoading() {

    if (needLoadingRequestCount === 0) {
        startLoading()
    }
    needLoadingRequestCount++

}

function tryHideFullScreenLoading() {

    if (needLoadingRequestCount <= 0) return
    needLoadingRequestCount--
    if (needLoadingRequestCount === 0) {
        endLoading()
    }

}
let loading

function startLoading() {

    var dialog = document.querySelector(".el-dialog")

    loading = app.config.globalProperties.$loading({
        target: dialog != undefined || dialog != null ? dialog : document.querySelector(".body"),
        lock: true,
        text: '加载中……',
        background: 'rgba(0, 0, 0, 0.7)'
    })
}

function endLoading() {
    loading.close()
}


const proxy = app.mount("#App")
export default proxy

