<template>
  <div class="list">
    <pagerlist
      :tableHeader="this.articlelogic.categoryOpHeader()"
      :tableData="categoryList"
    >
      <template v-slot:btn="slotProps">
        <el-button type="primary" @click.stop="showEditDial(slotProps.row)">
          修改
        </el-button>
      </template>
    </pagerlist>
    <pager
      :handlerCurrentPage="handlerCurrentPage"
      :page="page"
      :numTotal="numTotal"
    />
    <el-dialog
      :model-value="showDialog"
      width="40rem"
      center
      :before-close="closeShowDial"
    >
      <div class="addFunc_title">修改文章分类</div>
      <hr />
      <el-form
        ref="editForm"
        :model="categoryForm"
        :rules="this.articlelogic.categoryRules"
      >
        <el-row :gutter="20">
          <el-col :span="10" align="right"> 文章分类名称： </el-col>
          <el-col :span="14">
            <el-form-item prop="categoryName">
              <el-input
                v-model="categoryForm.categoryName"
                placeholder="请输入文章分类名称"
              />
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="10" align="right"> 文章分类编码： </el-col>
          <el-col :span="14">
            <el-form-item prop="categoryCode">
              <el-input
                v-model="categoryForm.categoryCode"
                disabled
                placeholder="请输入文章分类编码"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="10" align="right"> 是否显示： </el-col>
          <el-col :span="14">
            <el-form-item prop="isShow">
              <el-select
                v-model="categoryForm.isShow"
                placeholder="请选择"
                class="admin_tel input77"
              >
                <el-option
                  v-for="item in this.articlelogic.isShow"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="20">
          <el-col :span="24" align="center">
            <el-button type="primary" @click.stop="saveCategory('editForm')"
              >确定</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import pagerlist from "@/components/listComponents/pagerList.vue";
import pager from "@/components/listComponents/pager.vue";

export default {
  components: { pagerlist, pager },
  data() {
    return {
      categoryList: [],
      page: 1,
      numTotal: 0,
      showDialog: false,
      categoryForm: {},
    };
  },
  mounted() {
    this.handlerCurrentPage();
  },
  methods: {
    handlerCurrentPage: function (page = 1) {
      this.articlelogic.categorySearchObj.page = page;
      this.articlelogic.categoryList().then((res) => {
        this.categoryList = res.articleCategoryList;
        this.numTotal = res.total;
      });
    },
    showEditDial: function (row) {
      this.showDialog = true;
      this.categoryForm = row;
    },
    closeShowDial(ref) {
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.showDialog = false;
          done();
        })
        .catch((_) => {});
    },
    saveCategory: function (ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.axios
            .post(
              this.baseURL + "Article/EditArticleCategory",
              this.categoryForm,
              this.headers()
            )
            .then((res) => {
              this.resolveData(res.data, "edit");
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.list .el-row {
  margin-top: 1rem;
  line-height: 2rem;
}
.el-col {
  color: white !important;
}
.list >>> .el-dialog span {
  color: black !important;
}
.list >>> .el-dialog .el-button span {
  color: white !important;
}
</style>