import { createRouter, createWebHashHistory } from 'vue-router'

import Login from "@/views/login"
import Index from "@/views/index"
import AppVersion from "@/views/appVersion"


import UserIndex from "@/views/userControl/userIndex"
import AddUser from "@/views/userControl/addUser"
import EditUser from '@/views/userControl/editUser'
import AssignRole from '@/views/userControl/assignRole'

import FuncIndex from "@/views/funcControl/funcIndex"
import AddFunc from "@/views/funcControl/addFunc"
import EditFunc from "@/views/funcControl/editFunc"

import RoleIndex from "@/views/roleControl/roleIndex"
import AddRole from "@/views/roleControl/addRole"
import EditRole from "@/views/roleControl/editRole"
import AssignFun from "@/views/roleControl/assignFun"

import TUserIndex from "@/views/tuserControl/tuserIndex"

import ChildIndex from "@/views/childControl/childIndex"

import TestIndex from "@/views/testControl/testIndex"
import AllTest from "@/views/testControl/allTest"

import ArticleIndex from "@/views/articleControl/articleIndex"
import AddArticle from "@/views/articleControl/addArticle"
import EditArticle from "@/views/articleControl/editArticle"
import DelArticle from "@/views/articleControl/delArticle"

import SysLog from "@/views/logControl/sysLog"
import TUserLog from "@/views/logControl/tuserLog"

import TestPositionIndex from "@/views/testPositionControl/testPositionIndex"
import AddTestPosition from "@/views/testPositionControl/addTestPosition"
import EditTestPosition from "@/views/testPositionControl/editTestPosition"

import DeviceTypeIndex from "@/views/deviceTypeControl/deviceTypeIndex"
import AddDeviceType from "@/views/deviceTypeControl/addDeviceType"
import EditDeviceType from "@/views/deviceTypeControl/editDeviceType"

import ArticleCategoryIndex from "@/views/articleControl/articleCategoryIndex"
import AddArticleCategory from "@/views/articleControl/addArticleCategory.vue"
import EditArticleCategory from "@/views/articleControl/editArticleCategory"



let otherRouter = [
  { path: "/", redirect: '/login.html' },
  { path: "/login.html", name: "登录", component: Login },
  { path: "/index.html", name: "首页", component: Index },
  { path: "/appversion.html", name: "App版本管理", component: AppVersion },];

let userRouter = [
  { path: '/userControl/userIndex.html', name: '用户列表', component: UserIndex },
  { path: '/userControl/addUser.html', name: '添加用户', component: AddUser },
  { path: '/userControl/editUser.html', name: '编辑用户', component: EditUser },
  { path: '/userControl/assignRole.html', name: '分配角色', component: AssignRole }];

let funRouter = [
  { path: "/funcControl/funcIndex.html", name: "功能列表", component: FuncIndex },
  { path: "/funcControl/addFunc.html", name: "添加功能", component: AddFunc },
  { path: "/funcControl/editFunc.html", name: "修改功能", component: EditFunc, }];

let roleRouter = [
  { path: "/roleControl/roleIndex.html", name: "角色列表", component: RoleIndex },
  { path: "/roleControl/addRole.html", name: "添加角色", component: AddRole },
  { path: "/roleControl/editRole.html", name: "修改角色", component: EditRole },
  { path: "/roleControl/assignFun.html", name: "分配功能", component: AssignFun }];

let tuserRouter = [{ path: "/tuserControl/tuserIndex.html", name: "终端用户列表", component: TUserIndex },]

let childRouter = [{ path: "/childControl/childIndex.html", name: "新生儿列表", component: ChildIndex },]

let testRouter = [
  { path: "/testControl/testIndex.html", name: "终端用户测量记录", component: TestIndex },
  { path: "/testControl/allTest.html", name: "测量记录", component: AllTest }]


let articleRouter = [
  { path: "/articleControl/articleIndex.html", name: "文章列表", component: ArticleIndex },
  { path: "/articleControl/addArticle.html", name: "添加文章", component: AddArticle },
  { path: "/articleControl/editArticle.html", name: "修改文章", component: EditArticle },
  { path: "/articleControl/delArticle.html", name: "删除文章", component: DelArticle }];

let logRouter = [
  { path: "/logControl/sysLog.html", name: "系统操作日志", component: SysLog },
  { path: "/logControl/tuserLog.html", name: "终端用户业务日志", component: TUserLog }]
let testPositionRouter = [
  { path: "/testPositionControl/testPositionIndex.html", name: "测量部位列表", component: TestPositionIndex },
  { path: "/testPositionControl/addTestPosition.html", name: "添加测量部位", component: AddTestPosition },
  { path: "/testPositionControl/editTestPosition.html", name: "修改测量部位", component: EditTestPosition }]
let deviceTypeRouter = [
  { path: "/deviceTypeControl/deviceTypeIndex.html", name: "设备类型列表", component: DeviceTypeIndex },
  { path: "/deviceTypeControl/addDeviceType.html", name: "添加设备类型", component: AddDeviceType },
  { path: "/deviceTypeControl/editDeviceType.html", name: "修改设备类型", component: EditDeviceType }]
let articleCategoryRouter = [
  { path: "/articleControl/articleCategoryIndex.html", name: "文章分类列表", component: ArticleCategoryIndex },
  { path: "/articleControl/addArticleCategory.html", name: "添加文章分类", component: AddArticleCategory },
  { path: "/articleControl/editArticleCategory.html", name: "修改文章分类", component: EditArticleCategory }]


const router = createRouter({
  history: createWebHashHistory(),
  routes: []
    .concat(otherRouter)
    .concat(userRouter)
    .concat(funRouter)
    .concat(roleRouter)
    .concat(tuserRouter)
    .concat(childRouter)
    .concat(articleRouter)
    .concat(testRouter)
    .concat(logRouter)
    .concat(testPositionRouter)
    .concat(deviceTypeRouter)
    .concat(articleCategoryRouter)
});
router.beforeEach((to, from, next) => {
  next()
});
router.afterEach((transition) => {
  let name = transition.name
  let item = router.options.routes.filter((ele) => { return ele.name == name })
  setTitle(item[0].name)

});
function setTitle(title) {
  document.title = title
  var mobile = navigator.userAgent.toLowerCase()
  if (/iphone|ipad|ipod/.test(mobile)) {
    var iframe = document.createElement("iframe")
    iframe.style.display = "none"
    // iframe.setAttribute("src", "")
    var iframeCallback = function () {
      setTimeout(function () {
        iframe.removeEventListener("load", iframeCallback)
        document.body.removeChild(iframe)
      }, 0)
    }
    iframe.addEventListener("load", iframeCallback)
    document.body.appendChild(iframe)
  }
}

export default router
