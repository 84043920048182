<template>
  <el-select
    v-model="searchVal"
    filterable
    remote
    reserve-keyword
    placeholder="请输入终端用户手机号"
    :remote-method="remoteMethod"
    @change="setSearchVal"
    :loading="false"
    clearable
  >
    <el-option
      v-for="item in tuserList"
      :key="item.id"
      :label="item.userName"
      :value="item.id"
    />
  </el-select>
  <div class="alertLogTitle">请输入完整手机号</div>
</template>

<script>
export default {
  data() {
    return { searchVal: "", tuserList: [] };
  },
  props: {
    search: {
      type: Function,
      default: function (page, searchVal) {},
    },
  },
  methods: {
    setSearchVal(val) {
      this.tuserlogic.searchObj.searchVal = val;
      let interval = setInterval(() => {
        if (this.userlogic.searchObj.searchVal === "") {
          this.searchVal = "";
          clearInterval(interval);
        }
      }, 500);
      if (val == "") {
        val = this.emptyId;
      }
      this.search(val);
    },
    remoteMethod(val) {
      let dd = document.querySelector(".alertLogTitle");
      if (val == "") {
        dd.style.display = "none";
        return;
      }
      if (val.length != 11) {
        dd.style.display = "inline";
        return;
      }
      dd.style.display = "none";
      this.tuserlogic.searchObj.searchVal = val;
      this.tuserlogic.tuserList().then((res) => {
        this.tuserList = res.tUserList;
      });
    },
  },
};
</script>
<style scoped>
.alertLogTitle {
  display: none;
  color: red;
  font-size: 0.8rem;
  margin-left: 1rem;
}
</style>