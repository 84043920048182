<template>
  <div class="body">
    <basecom />

    <categoryindex />
  </div>
</template>

<script>
import categoryindex from "@/components/articleComponents/categoryIndex.vue";
import basecom from "@/components/base.vue";
export default {
  components: { basecom, categoryindex },
};
</script>

<style>
</style>