<template>
  <div>
    <funListTable
      :tableData="this.funlogic.loadFunList()"
      :tableHeader="this.funlogic.opHeader()"
      :lazyLoad="this.funlogic.getChildFunList"
    >
      <template v-slot:btn="scope">
        <el-button
          type="warning"
          v-if="scope.row.id != emptyId"
          @click.stop="editFunc(scope.row)"
        >
          编辑
        </el-button>
      </template>
    </funListTable>
    <el-dialog
      :model-value="editFunDial"
      width="65rem"
      top="0rem"
      center
      :before-close="noEditFun"
    >
      <div class="addFunc_title">修改功能</div>
      <hr />
      <div class="xgmm_form">
        <el-form
          ref="editFuncForm"
          :model="funFrom"
          :rules="this.funlogic.rules"
        >
          <el-row
            style="display: flex; flex-wrap: wrap; justify-content: center"
          >
            <el-col :span="10">
              <el-form-item prop="fName">
                <div>
                  <span><i>*</i>功能名称：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="funFrom.fName"
                    maxlength="30"
                    placeholder="请输入功能名称"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10" v-if="funFrom.fpid != emptyId">
              <el-form-item>
                <div>
                  <span><i>*</i>上级功能：</span>
                  <el-cascader
                    v-model="funFrom.fpName"
                    class="admin_tel input77"
                    :show-all-levels="false"
                    :props="parentListProps"
                    @change="fpNameChange"
                    :placeholder="fpName"
                  >
                  </el-cascader>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item prop="fController">
                <div>
                  <span class="battery"><i>*</i>功能控制器：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="funFrom.fController"
                    placeholder="请输入功能控制器"
                  ></el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item prop="status">
                <div>
                  <span><i>*</i>激活状态：</span>
                  <el-select
                    v-model="funFrom.status"
                    placeholder="请选择"
                    class="admin_tel input77"
                  >
                    <el-option
                      v-for="item in this.funlogic.TF1"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item prop="fAction">
                <div>
                  <span><i>*</i>功能方法：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="funFrom.fAction"
                    placeholder="请输入功能方法"
                  ></el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item prop="fType">
                <div>
                  <span><i>*</i>功能类型：</span>
                  <el-select
                    v-model="funFrom.fType"
                    placeholder="请选择"
                    class="admin_tel input77"
                  >
                    <el-option
                      v-for="item in this.funlogic.TFBtn"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="10">
              <el-form-item prop="fIcon">
                <div>
                  <span><i>*</i>功能图标：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="funFrom.fIcon"
                    placeholder="功能图标可为空"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="10">
              <el-form-item prop="fOrder">
                <div>
                  <span><i>*</i>功能排序：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="funFrom.fOrder"
                    maxlength="30"
                    type="Number"
                    placeholder="请输入功能排序值"
                  ></el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item prop="isMainFunc">
                <div>
                  <span><i>*</i>系统功能：</span>
                  <el-select
                    v-model="funFrom.isMainFunc"
                    placeholder="请选择"
                    class="admin_tel input77"
                  >
                    <el-option
                      v-for="item in this.funlogic.TF"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item prop="isShow">
                <div>
                  <span><i>*</i>是否显示：</span>
                  <el-select
                    v-model="funFrom.isShow"
                    placeholder="请选择"
                    class="admin_tel input77"
                  >
                    <el-option
                      v-for="item in this.funlogic.Show"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="20" v-if="funFrom.fpid != emptyId">
              <el-form-item prop="fDescription">
                <div>
                  <span><i>*</i>功能描述：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="funFrom.fDescription"
                    maxlength="100"
                    style="width: 42rem"
                    placeholder="请输入功能描述"
                  ></el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10" v-if="funFrom.fpid == emptyId">
              <el-form-item prop="fDescription">
                <div>
                  <span><i>*</i>功能描述：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="funFrom.fDescription"
                    maxlength="100"
                    placeholder="请输入功能描述"
                  ></el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="sureBtn">
        <el-button class="qx" @click="noEditFun('editFuncForm')" type="info"
          >取消</el-button
        >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-button class="qd" @click="okEditFun('editFuncForm')" type="primary"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import proxy from "@/main";
import funListTable from "@/components/listComponents/treeList";

export default {
  name: "addFunc",
  components: { funListTable },
  data() {
    return {
      fpName: "",
      emptyId: this.emptyId,
      editFunDial: false,
      funFrom: {},
      parentListProps: {
        checkStrictly: true,
        lazy: true,
        lazyLoad(node, resolve) {
          if (node.root == true) {
            node.value = proxy.emptyId;
          }
          let aa = {
            fpid: node.value,
            fname: "",
          };

          proxy.axios
            .post(proxy.baseURL + "Function/FunctionList", aa, proxy.headers())
            .then((res) => {
              let data = proxy.resolveData(res.data);

              let childFunList = data.functionList;
              let nodes = childFunList.map((item) => ({
                value: item.id,
                label: item.functionName,
                leaf: item.hasChildren === false,
              }));
              resolve(nodes);
            });
        },
      },
    };
  },
  methods: {
    fpNameChange(e) {
      this.funFrom.fpid = e[e.length - 1];
    },

    noEditFun() {
      //弹窗关闭
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.editFunDial = false;
          done();
        })
        .catch((_) => {});
    },
    okEditFun(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.editFunDial = false;

          this.axios
            .post(
              this.baseURL + "Function/FunctionEdit",
              this.funFrom,
              this.headers()
            )
            .then((res) => {
              this.resolveData(res.data, "edit");
            });
        }
      });
    },

    editFunc(row) {
      //弹窗开启
      this.editFunDial = true;
      let obj = { ...row };
      this.funFrom = {
        id: row.id,
        fpid: row.fpid,
        fName: row.functionName,
        fDescription: row.functionDescription,
        fType: row.functionTypeCode,
        fController: row.fController,
        fAction: row.fAction,
        fIcon: row.fIcon,
        fOrder: row.fOrder,
        status: row.activateStatus,
        isMainFunc: row.isMainFunc,
        isShow: row.isShow,
      };
      this.fpName = row.fpName;

      this.resetForm("editFuncForm");
    },

    //清除验证提示方法
    resetForm(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
    },
  },
};
</script>
