
<template>
  <div class="body">
    <basecom />
   
      <ver />
     </div>
</template>
<script>
import basecom from "@/components/base";
import ver from "@/components/appVersionComponents/appVersion";

export default {
  components: { basecom, ver },
};
</script>




