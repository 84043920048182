<template>
  <div class="body">
    <basecom />
    <div class="list">
      <fun />
    </div>
  </div>
</template>
<script>
import basecom from "@/components/base";
import fun from "@/components/roleComponents/assignFun";
export default { 
  components: {
    basecom,
    fun,
  },
};
</script>


