<template>
  <div class="search">
    <el-row :gutter="10" type="flex" align="middle">
      <el-col :span="3.5">
        <div class="listTitle">
          新生儿列表·共<span style="color: coral">{{ numTotal }}</span
          >人
        </div>
      </el-col>
      <el-col :span="10"><search :search="search" /> </el-col>

      <!-- <el-col :span="1.5">
        <el-button @click="search" type="primary">查询</el-button>
      </el-col> -->
    </el-row>
  </div>
</template>

<script>
import search from "@/components/searchComponents/tuserAutoSearch.vue";
export default {
  components: { search },
  props: {
    numTotal: {
      type: Number,
      default: 0,
    },
    search: {
      type: Function,
      default: function (page, searchVal) {},
    },
  },
};
</script>
<style scoped>
.search >>> .el-input {
  width: 100% !important;
}
</style>


