<template>
  <div class="search">
    <el-row :gutter="10" type="flex" align="middle">
      <el-col :span="3.5">
        <div class="listTitle">
          终端用户列表·共<span style="color: coral">{{ numTotal }}</span
          >人
        </div>
      </el-col>
      <el-col :span="3.5">
        <el-input
          v-model="searchVal"
          placeholder="请输入昵称/手机号"
          @change="setSearchVal"
          prefix-icon="search"
          @keyup.enter.native="search"
        >
        </el-input>
      </el-col>

      <el-col :span="1.5">
        <el-button @click="search" type="primary">查询</el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchVal: "",
    };
  },
  props: {
    numTotal: {
      type: Number,
      default: 0,
    },
    search: {
      type: Function,
      default: function (page, searchVal) {},
    },
  },

  methods: {
    setSearchVal() {
      this.tuserlogic.searchObj.searchVal = this.searchVal;
      let interval = setInterval(() => {
        if (this.userlogic.searchObj.searchVal === "") {
          this.searchVal = "";
          clearInterval(interval);
        }
      }, 500);
    },
  },
};
</script>


