<template>
  <div class="body">
    <basecom />

    <delcom />
  </div>
</template>

<script>
import delcom from "@/components/articleComponents/delete";
import basecom from "@/components/base";
export default {
  components: { basecom, delcom },
};
</script>

<style>
</style>