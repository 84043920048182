<template>
  <div>
    <roleList
      :tableData="this.rolelogic.roleList()"
      :tableHeader="this.rolelogic.opHeader()"
      :lazyLoad="this.rolelogic.getChildRoleList"
    >
      <template v-slot:btn="slotProps">
        <el-button
          type="primary"
          v-if="slotProps.row.rpid != emptyId"
          @click.stop="editrole(slotProps.row)"
        >
          修改角色
        </el-button>
      </template>
    </roleList>
    <el-dialog
      :model-value="editroleDial"
      width="30rem"
      center
      :before-close="nosureEditRole"
    >
      <div class="addFunc_title">修改角色</div>
      <hr />
      <div class="xgmm_form">
        <el-form
          ref="editroleForm"
          :model="roleForm"
          :rules="this.rolelogic.rules"
        >
          <el-row
            style="display: flex; flex-wrap: wrap; justify-content: center"
          >
            <el-col :span="18">
              <el-form-item prop="rpName" v-if="roleForm.rpid != emptyId">
                <div>
                  <span><i>*</i>上级角色：</span>
                  <el-cascader
                    v-model="roleForm.rpName"
                    :options="roleTopNode"
                    class="admin_tel input77"
                    :show-all-levels="false"
                    :props="this.rolelogic.roleProps"
                    @change="roleChange"
                    :placeholder="rpName"
                  >
                  </el-cascader>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="18">
              <el-form-item prop="roleName">
                <div>
                  <span><i>*</i>角色名称：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="roleForm.roleName"
                    maxlength="30"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="sureBtn">
        <el-button
          class="qx"
          @click="nosureEditRole('editroleForm')"
          type="info"
          >取消</el-button
        >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-button
          class="qd"
          @click="sureEditRole('editroleForm')"
          type="primary"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Vue from "vue";
import roleList from "@/components/listComponents/treeList";
export default {
  name: "edit",
  components: { roleList },
  data() {
    return {
      roleTopNode: [],
      childRoleList: [],
      editroleDial: false,
      roleForm: {},
      rpName: "",
      emptyId: this.emptyId,
    };
  },
  mounted() {
    this.rolelogic.roleList().forEach((item) => {
      this.roleTopNode.push({
        value: item.id,
        label: item.roleName,
        leaf: item.hasChildren === false,
      });
    });
  },
  methods: {
    roleChange(e) {
      this.roleForm.rpid = e[e.length - 1];
    },
    sureEditRole(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          let aa = {
            roleName: this.roleForm.roleName,
            id: this.roleForm.id,
            rpid: this.roleForm.rpid,
          };

          this.axios
            .post(this.baseURL + "Role/RoleEdit", aa, this.headers())
            .then((res) => {
              this.resolveData(res.data, "edit");
            });
          this.editroleDial = false;
        }
      });
    },
    nosureEditRole(ref) {
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.editroleDial = false;
          done();
        })
        .catch((_) => {});
    },
    editrole(row) {
      let obj = { ...row };
      this.roleForm = obj;
      this.rpName = this.roleForm.rpName;
      this.editroleDial = true;
      this.resetForm("editroleForm");
    },
    resetForm(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
    },
  },
};
</script>