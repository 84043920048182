<template>
  <pagerlist
    :tableHeader="logHeader"
    :tableData="logList"
    :page="this.searchForm.page"
  />
  <pager
    :page="this.searchForm.page"
    :numTotal="numTotal"
    :handleCurrentChange="handleCurrentChange"
  />
</template>

<script>
import pagerlist from "@/components/listComponents/pagerList";
import pager from "@/components/listComponents/pager";
export default {  
  components: {
    pagerlist,
    pager,
  },
  data() {
    return {
      logHeader: [
        { type: "index", label: "序号", width: "80" },
        { prop: "title", label: "操作内容" },
        { prop: "createDate", label: "发生时间", width: "195" },
      ],
      logList: [],
      numTotal: 0,
      searchForm: {
        page: 1,
        userId: this.emptyId,
      },
    };
  },
  mounted() {
    this.handleCurrentChange();
  },
  methods: {
    handleCurrentChange: function (page = 1) {
      this.searchForm.page = page;

      this.axios
        .post(this.baseURL + "SysLog/LogList", this.searchForm, this.headers())
        .then((res) => {
          let data = this.resolveData(res.data);
          this.logList = data.logList;
          this.numTotal = data.total;
        });
    },
  },
};
</script>




