<template>
  <div class="body">
    <basecom />
    <div class="list">
      <index />
    </div>
  </div>
</template>

<script>
import basecom from "@/components/base";
import index from "@/components/testPositionComponents/index";
export default {
  components: { basecom, index },
};
</script>

<style>
</style>