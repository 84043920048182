<template>
  <div class="body">
    <basecom />
    <div class="list">
      <add />
    </div>
  </div>
</template>

<script>
import basecom from "@/components/base";
import add from "@/components/funcComponents/add";

export default { 
  components: {
    basecom,
    add,
  },
};
</script>



