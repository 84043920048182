<template>
  <div class="body">
    <basecom />

    <add />
  </div>
</template>

<script>
import add from "@/components/articleComponents/addCategory.vue";
import basecom from "@/components/base";
export default {
  components: { basecom, add },
};
</script>

<style>
</style>