<template>
  <div >
    <top></top>
    <left></left>
    <el-breadcrumb separator-icon="ArrowRight">
      <el-breadcrumb-item v-for="item in this.blist">
        {{ item }}
      </el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
import top from "@/components/top";
import left from "@/components/left";
export default {
  components: {
    top,
    left,
  },
  data() {
    return {
      blist: [this.SystemShortTitle, "首页"],
    };
  },
  mounted() {
    this.breadcrumb();
  },
  methods: {
    breadcrumb() {
      let list = this.session("breadcrumb");
      if (list != null) {
        this.blist = list.split(",");
      }
    },
  },
};
</script>
<style scoped>
.el-breadcrumb >>> {
  margin-left: 20rem;
  margin-bottom: 2rem;
}
.el-breadcrumb >>> .el-breadcrumb__item .el-breadcrumb__inner {
  color: white !important;
  font-size: 1rem;
}
</style>
