<template>
  <div>
    <funListTable
      :tableData="this.funlogic.loadFunList()"
      :tableHeader="this.funlogic.opHeader()"
      :lazyLoad="this.funlogic.getChildFunList"
    >
      <template v-slot:btn="slotProps">
        <el-button
          type="primary"
          @click.stop="addFunc(slotProps.row.id, slotProps.row.functionName)"
        >
          添加子功能
        </el-button>
      </template>
    </funListTable>

    <el-dialog
      :model-value="addFunFrom"
      width="65rem"
      center
      top="0rem"
      :before-close="nosureAddFun"
    >
      <div class="addFunc_title">添加功能</div>
      <hr />
      <div class="xgmm_form">
        <el-form ref="addFunc" :model="funFrom" :rules="this.funlogic.rules">
          <el-row
            style="display: flex; flex-wrap: wrap; justify-content: center"
          >
            <el-col :span="10">
              <el-form-item prop="fName">
                <div>
                  <span><i>*</i>功能名称：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="funFrom.fName"
                    maxlength="30"
                    placeholder="请输入功能名称"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item>
                <div>
                  <span><i>*</i>上级功能：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="funFrom.fpName"
                    maxlength="30"
                    :disabled="true"
                  ></el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item prop="fController">
                <div>
                  <span class="battery"><i>*</i>功能控制器：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="funFrom.fController"
                    placeholder="请输入功能控制器"
                  ></el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item prop="status">
                <div>
                  <span><i>*</i>激活状态：</span>
                  <el-select
                    v-model="funFrom.status"
                    placeholder="请选择"
                    class="admin_tel input77"
                  >
                    <el-option
                      v-for="item in this.funlogic.TF1"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item prop="fAction">
                <div>
                  <span><i>*</i>功能方法：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="funFrom.fAction"
                    placeholder="请输入功能方法"
                  ></el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item prop="fType">
                <div>
                  <span><i>*</i>功能类型：</span>
                  <el-select
                    v-model="funFrom.fType"
                    placeholder="请选择"
                    class="admin_tel input77"
                  >
                    <el-option
                      v-for="item in this.funlogic.TFBtn"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="10">
              <el-form-item prop="fIcon">
                <div>
                  <span><i>*</i>功能图标：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="funFrom.fIcon"
                    maxlength="20"
                    placeholder="功能图标可为空"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="10">
              <el-form-item prop="fOrder">
                <div>
                  <span><i>*</i>功能排序：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="funFrom.fOrder"
                    maxlength="30"
                    type="Number"
                    placeholder="请输入功能排序值"
                  ></el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item prop="isMainFunc">
                <div>
                  <span><i>*</i>系统功能：</span>
                  <el-select
                    v-model="funFrom.isMainFunc"
                    placeholder="请选择"
                    class="admin_tel input77"
                  >
                    <el-option
                      v-for="item in this.funlogic.TF"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="10">
              <el-form-item prop="isShow">
                <div>
                  <span><i>*</i>显示状态：</span>
                  <el-select
                    v-model="funFrom.isShow"
                    placeholder="请选择"
                    class="admin_tel input77"
                  >
                    <el-option
                      v-for="item in this.funlogic.Show"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="20">
              <el-form-item prop="fDescription">
                <div>
                  <span><i>*</i>功能描述：</span>
                  <el-input
                    class="admin_tel input77"
                    style="width: 42rem"
                    v-model="funFrom.fDescription"
                    maxlength="100"
                    placeholder="请输入功能描述"
                  ></el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="sureBtn">
        <el-button class="qx" @click="nosureAddFun('addFunc')" type="info"
          >取消</el-button
        >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-button class="qd" @click="sureAddFun('addFunc')" type="primary"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import funListTable from "@/components/listComponents/treeList";

export default {
  name: "addFunc",
  components: { funListTable },
  data() {
    return {
      addFunFrom: false,
      funFrom: {},
      mbFpid: "",
    };
  },
  
  methods: {
    nosureAddFun() {
      //弹窗关闭
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.addFunFrom = false;
          done();
        })
        .catch((_) => {});
    },
    sureAddFun(ref) {
      //下面为验证,需要设置form的ref
      this.$refs[ref].validate((valid) => {
        if (valid) {
          //弹窗关闭
          this.addFunFrom = false;
          let funFrom = this.funFrom;
          //这里写请求
          funFrom.fpid = this.mbFpid;
          funFrom.fOrder = Number(funFrom.fOrder);
          delete funFrom.fpName;

          this.axios
            .post(
              this.baseURL + "Function/FunctionAdd",
              funFrom,
              this.headers()
            )
            .then((res) => {
              this.resolveData(res.data, "add");
            });
        } else {
          return false;
        }
      });
    },
    addFunc(id, name) {
      this.addFunFrom = true;
      this.funFrom = {};
      this.funFrom.fpName = name;
      //data中加入当前所选功能的fpid
      this.mbFpid = id;
      //数据清空
      //验证提示消除
      this.resetForm("addFunc");
    },
    //清除验证提示方法
    resetForm(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
    },
  },
};
</script>
