<template>
  <div class="body">
    <basecom />

    <edit />
  </div>
</template>

<script>
import edit from "@/components/articleComponents/edit";
import basecom from "@/components/base";
export default {
  components: { basecom, edit },
};
</script>

<style>
</style>