<template>
  <pagerlist
    :tableHeader="this.devicetypelogic.opHeader()"
    :tableData="deviceTypeList"
    :page="this.devicetypelogic.searchForm.page"
  >
    <template v-slot:btn="slotProps">
      <el-button
        type="primary"
        v-if="slotProps.row.rpid != emptyId"
        @click.stop="edit(slotProps.row)"
      >
        编辑
      </el-button>
    </template>
  </pagerlist>
  <pager
    :page="this.devicetypelogic.searchForm.page"
    :numTotal="numTotal"
    :handleCurrentChange="handleCurrentChange"
  />
  <el-dialog
    :model-value="showDial"
    width="30rem"
    center
    :before-close="noEdit"
  >
    <div class="addFunc_title">修改设备类型</div>
    <hr />
    <div class="xgmm_form">
      <el-form
        ref="editForm"
        :model="modelForm"
        :rules="this.devicetypelogic.rules"
      >
        <el-row>
          <el-col :span="18">
            <el-form-item prop="typeName">
              <div>
                <span><i>*</i>设备类型名称：</span>
                <el-input
                  class="admin_tel input77"
                  v-model="modelForm.typeName"
                  maxlength="30"
                >
                </el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="18">
            <el-form-item prop="typeCode">
              <div>
                <span><i>*</i>设备类型编码：</span>
                <el-input
                  type="number"
                  class="admin_tel input77"
                  v-model="modelForm.typeCode"
                  maxlength="30"
                >
                </el-input>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="sureBtn">
      <el-button class="qx" @click="noEdit('editForm')" type="info"
        >取消</el-button
      >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <el-button class="qd" @click="okEdit('editForm')" type="primary"
        >确定</el-button
      >
    </div>
  </el-dialog>
</template>

<script>
import pagerlist from "@/components/listComponents/pagerList";
import pager from "@/components/listComponents/pager";
export default {
  components: {
    pagerlist,
    pager,
  },
  data() {
    return {
      numTotal: 0,
      deviceTypeList: [],
      showDial: false,
      modelForm: {},
    };
  },
  mounted() {
    this.handleCurrentChange();
  },
  methods: {
    handleCurrentChange: function (page = 1) {
      this.devicetypelogic.getDeviceTypeList().then((res) => {
        this.deviceTypeList = res.deviceTypeList;
      });
    },
    edit: function (row) {
      this.modelForm = row;
      this.showDial = true;
    },
    noEdit: function () {
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.resetForm("editForm");
          this.showDial = false;

          done();
        })
        .catch((_) => {});
    },
    okEdit: function (ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.modelForm.typeCode = Number(this.modelForm.typeCode);
          this.axios
            .post(
              this.baseURL + "Base/EditDeviceType",
              this.modelForm,
              this.headers()
            )
            .then((res) => {
              this.resolveData(res.data, "edit");
              this.showDial = false;
            });
        }
      });
    },
    resetForm: function (refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
    },
  },
};
</script>




