  <template>
  <div style="text-align: center">
    <el-pagination
      background
      @current-change="handleCurrentChange"
      :page-size="pageSize"
      layout="total,  prev, pager, next, jumper"
      :total="numTotal"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "pager",
  components: {},
  data() {
    return {
      pageSize: this.PageSize,
    };
  },
  props: {
    page: {
      type: Number,
      default: 1,
    },
    numTotal: {
      type: Number,
      default: 0,
    },
    handleCurrentChange: {
      type: Function,
      default: function (page) {},
    },
  },
};
</script>
<style scoped>
.el-pagination {
  margin-top: 1rem;
  margin-left: auto;
  margin-right: auto;
  display: inline-flex;
  text-align: center !important;
  color: white !important;
}
.el-pagination >>> .el-pagination__total {
  color: white !important;
}
.el-pagination >>> .el-pagination__jump {
  color: white !important;
}
.el-pagination >>> .el-input {
  width: auto !important;
}
</style>
  
