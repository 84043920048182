import proxy from '../main'
export default {
    mapZoomList: function () {
        let arr = []
        for (let i = 3; i < 21; i++) {
            let obj = {
                value: i,
                label: i
            }
            arr.push(obj)
        }
        return arr
    },
    searchObj: {
        page: 1,
        userName: "",
        email: "",
        mobile: ""
    },
    page: 1,
    TF: [
        { value: false, label: "否" },
        { value: true, label: "是" }
    ],
    rules: {
        userName: [
            { required: true, message: "请输入用户名称", trigger: "blur" },
        ],
        mobile: [
            { required: true, message: "手机号不能为空", trigger: "blur" },
            { min: 11, max: 11, message: "请输入11位手机号码", trigger: "change" },
            { pattern: /^1[3-9]\d{9}$/, message: "请输入正确的手机号码", }
        ],
        idCard: [
            { required: true, message: '请填写证件号码', trigger: 'change' },
            {
                pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$)/,
                message: '证件号码格式有误！',
                trigger: 'change'
            }
        ],
        email: [
            { required: true, type: "email", message: "请输入正确的邮箱地址", trigger: "change" },
        ],
        mobileReceiveStatus: [
            { required: true, message: "请选择激活状态", trigger: "change" },
        ],
        emailReceiveStatus: [
            { required: true, message: "请输入管辖区域名称", trigger: "change" },
        ],
    },

    listHeader: function () {
        return [
            { type: "index", label: "序号", width: "100" },
            { prop: "userName", label: "用户名称" },
            { prop: "mobile", label: "手机号" },
            { prop: "email", label: "邮箱", width: "200" },
            { prop: "createDate", label: "添加时间", width: "195" },
            { prop: "loginDate", label: "登录日期", width: "195" },

        ];
    },
    opHeader: function () {
        let header = this.listHeader();
        header.push({ prop: "btn", slot: "btn", label: "操作" });
        return header;
    },
    getUserList: function () {
        return new Promise((resolve) => {
            proxy.axios.post(proxy.baseURL + "User/UserList", this.searchObj, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data));
                })
        });

    },
    reset: function () {
        this.searchObj = { page: 1, userName: "", email: "", mobile: "" };
    }
}