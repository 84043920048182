import proxy from '../main'
export default {
    searchForm: {
        page: 1
    },
    rules: {
        typeName: [{ required: true, message: "请输入设备类型名称", trigger: "blur" },],
        typeCode: [{ required: true, message: "请输入设备类型编码", trigger: "blur" },]
    },
    listHeader: function () {
        return [
            { type: "index", label: "序号", width: "100" },
            { prop: "typeName", label: "设备类型名称" },
            { prop: "typeCode", label: "设备类型编码" },
        ];
    },
    opHeader: function () {
        let header = this.listHeader();
        header.push({ prop: "btn", slot: "btn", label: "操作" });
        return header;
    },
    getDeviceTypeList: function () {
        return new Promise((resolve) => {

            proxy.axios.post(proxy.baseURL + "Base/DeviceTypeList", this.searchForm, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data));
                })
        });
    }

}
