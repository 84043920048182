<template>
  <el-row>
    <el-col :span="2.5" style="line-height: 2rem">终端用户关联查询： </el-col>
    <el-col :span="10"><search :search="search" /> </el-col>
  </el-row>

  <pagerlist
    :tableHeader="logHeader"
    :tableData="logList"
    :page="this.searchForm.page"
  />
  <pager
    :page="this.searchForm.page"
    :numTotal="numTotal"
    :handleCurrentChange="handleCurrentChange"
  />
</template>

<script>
import pagerlist from "@/components/listComponents/pagerList.vue";
import pager from "@/components/listComponents/pager.vue";
import search from "@/components/searchComponents/tuserAutoSearch.vue";
export default {
  components: {
    pagerlist,
    pager,
    search,
  },
  data() {
    return {
      logHeader: [
        { type: "index", label: "序号", width: "80" },
        { prop: "title", label: "操作内容" },
        { prop: "createDate", label: "发生时间", width: "195" },
      ],
      logList: [],
      numTotal: 0,
      searchForm: {
        page: 1,
        userId: this.emptyId,
      },
    };
  },
  mounted() {
    this.handleCurrentChange();
  },
  methods: {
    handleCurrentChange: function (page = 1) {
      this.searchForm.page = page;
      this.axios
        .post(this.baseURL + "TLog/LogList", this.searchForm, this.headers())
        .then((res) => {
          let data = this.resolveData(res.data);
          this.logList = data.logList;
          this.numTotal = data.total;
        });
    },
    search: function (val) {
      this.searchForm.userId = val;
      this.handleCurrentChange();
    },
  },
};
</script>
<style scoped>
.el-row {
  margin-bottom: 1rem;
}
</style>




