import proxy from '../main'
export default {
    pageObj: {
        page: 1
    },
    tuserListHeader: function () {
        return proxy.tuserlogic.listHeader();
    },
    opHeader: function () {
        let header = this.tuserListHeader();
        header.push({ prop: "btn", slot: "btn", label: "操作" });
        return header;
    },

    childListHeader: function () {
        return [
            { type: "index", label: "序号", width: "100" },
            { prop: "childName", label: "宝宝昵称" },
            { prop: "sexText", label: "性别" },
            { prop: "birthday", label: "生日", width: "200" },
            { prop: "heightText", label: "身高" },
            { prop: "weightText", label: "体重" },
            { prop: "pregnancyText", label: "孕周" },
            { prop: "isPrematureText", label: "早产状态" },
            { prop: "braceletId", label: "手环ID", width: "200" },
        ];
    },


    childList: function (obj) {
        return new Promise((resolve) => {
            let aa = { id: obj };
            proxy.axios.post(proxy.baseURL + "TChild/TChildList", aa, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data));
                })
        });

    },
    allChildList: function () {
        return new Promise((resolve) => {

            proxy.axios.post(proxy.baseURL + "TChild/allChildList", this.pageObj, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data));
                })
        });
    }
}