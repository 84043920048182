import proxy from '../main'
export default {
    rules: {
        roleName: [
            { required: true, message: "请输入角色名称", trigger: "blur" },
        ]
    },
    roleProps: {
        checkStrictly: true,
        lazy: true,
        lazyLoad(node, resolve) {
            if (node.root != true) {
                let aa = {
                    rpid: node.value,
                };
                proxy.axios
                    .post(
                        proxy.baseURL + "Role/RoleList",
                        aa,
                        proxy.headers()
                    )
                    .then((res) => {
                        let data = proxy.resolveData(res.data);

                        let childRoleList = data.roleList;
                        let nodes = childRoleList.map((item) => ({
                            value: item.id,
                            label: item.roleName,
                            leaf: item.hasChildren === false,
                        }));
                        resolve(nodes);
                    });
            }
        },
    },
    listHeader: function () {
        return [
            { type: "index", label: "序号", width: "100" },
            { prop: "roleName", label: "角色名称" },
            { prop: "rpName", label: "上级角色名称" },
            { prop: "createDate", label: "创建时间" },
            { prop: "updateDate", label: "修改时间" },
        ];
    },
    opHeader: function () {
        let header = this.listHeader();
        header.push({ prop: "btn", slot: "btn", label: "操作" });
        return header;
    },
    roleList: function () {
        return proxy.getRole();
    },
    getChildRoleList(tree, treeNode, resolve) {
        let aa = {
            rpid: tree.id,
        }
        proxy.axios.post(proxy.baseURL + 'Role/RoleList', aa, proxy.headers()).then(res => {
            let data = proxy.resolveData(res.data)
            resolve(data.roleList)
        })
    },

}