<template>
  <div class="list">
    <articleSearch :numTotal="numTotal" :search="search" />
    <pagerlist
      :tableHeader="this.articlelogic.listHeader()"
      :tableData="articleList"
    />
    <pager
      :handleCurrentChange="handlerCurrentPage"
      :page="page"
      :numTotal="numTotal"
    />
  </div>
</template>

<script>
import articleSearch from "@/components/searchComponents/articleSearch.vue";
import pagerlist from "@/components/listComponents/pagerList.vue";
import pager from "@/components/listComponents/pager.vue";

export default {
  components: { pagerlist, pager, articleSearch },
  data() {
    return {
      articleList: [],
      page: 1,
      numTotal: 0,
    };
  },
  mounted() {
    this.handlerCurrentPage();
  },
  methods: {
    handlerCurrentPage: function (page = 1) {
      this.articlelogic.searchObj.page = page;
      this.articlelogic.articleList().then((res) => {
        this.articleList = res.articleList;
        this.numTotal = res.total;
      });
    },
    search: function () {
      this.handlerCurrentPage();
    },
  },
};
</script>

<style>
</style>