<template>
  <div class="body">
    <basecom></basecom>
    <div class="list">
      <add></add>
    </div>
  </div>
</template>

<script>
import basecom from "@/components/base";
import add from "@/components/userComponents/add";

export default {
  name: "addUser",
  components: {
    basecom,
    add,
  },
};
</script>

