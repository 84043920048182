<template>
  <div class="list">
    <div class="addFunc_title">系统运行状态</div>
    <hr />
    <div class="boxs">
      <!-- <div class="cpu">Cpu负载：</div> -->
      <div class="box">
        <div>
          服务运行状态：<span>{{ sysStatus.runStatus }}</span>
        </div>
      
        <div>
          缓存运行状态：<span>{{ sysStatus.runStatus }}</span>
        </div>
      </div>
      <!-- <div class="box">
        <div>内存总量：</div>
        <div>空余内存：</div>
        <div>使用内存：</div>
      </div> -->
    </div>
    <div class="boxs">
      <div class="box">
        <div>
          总用户注册数： <span>{{ sysStatus.userCount }}</span>
        </div>
        <div>
          今日新增注册：<span>{{ sysStatus.userTodayCount }}</span>
        </div>
        <div>
          当前在线人数：<span>{{ sysStatus.onlineUserCount }}</span>
        </div>
      </div>
      <div class="box">
        <div>
          婴儿总记录数：<span>{{ sysStatus.childCount }}</span>
        </div>
        <div>
          已测量婴儿数：<span>{{ sysStatus.childFinishTestCount }}</span>
        </div>
        <div>
          未测量婴儿数：<span>{{ sysStatus.childNotTestCount }}</span>
        </div>
      </div>
      <div class="box">
        <div>
          测量记录总数：<span>{{ sysStatus.testLogCount }}</span>
        </div>
        <div>&nbsp;</div>
        <div>
          今日新增测量记录数：<span>{{ sysStatus.testLogTodayCount }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sysStatus: {},
    };
  },
  mounted() {
    this.axios
      .get(this.baseURL + "Base/SysStatus", this.headers())
      .then((res) => {
        this.sysStatus = this.resolveData(res.data);
        this.sysStatus.runStatus = "运行中";
      });
  },
};
</script>

<style scoped>
.boxs {
  width: 98%;
  text-align: center;
  height: auto;
  overflow: hidden;
}
.box {
  width: 15rem;
  background-color: transparent;
  color: white;
  display: inline-block;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
  text-align: left;
  line-height: 2.5rem;
  padding: 1rem;
  border: 1px solid white;
  border-radius: 5px;
  vertical-align: top;
}
.box span {
  margin-right: 1rem;
  float: right;
  color: white;
}
.cpu {
  font-size: 2.5rem;
}
</style>