<template>
  <div>
    <div class="search">
      <userSearch
        :reset="reset"
        :search="search"
        :numTotal="numTotal"
        :addUser="addUser"
      ></userSearch>
    </div>
    <userlist
      :tableHeader="this.userlogic.listHeader()"
      :tableData="userList"
      :page="page"
    >
    </userlist>
    <pager
      :page="page"
      :numTotal="numTotal"
      :handleCurrentChange="handleCurrentChange"
    >
    </pager>
    <el-dialog
      :model-value="addUserFrom"
      center
      top="10rem"
      :before-close="nosureAddUser"
    >
      <div class="addFunc_title">添加用户</div>
      <hr />
      <div class="xgmm_form">
        <el-form ref="AddUser" :model="UserFrom" :rules="this.userlogic.rules">
          <el-row
            style="display: flex; flex-wrap: wrap; justify-content: center"
          >
            <el-col :span="24" :md="10">
              <el-form-item prop="userName">
                <div>
                  <span><i>*</i>用户名称：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="UserFrom.userName"
                    maxlength="30"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="10">
              <el-form-item prop="mobile">
                <div>
                  <span>&nbsp;&nbsp;&nbsp;<i>*</i>手机号：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="UserFrom.mobile"
                    maxlength="30"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="10">
              <el-form-item prop="idCard">
                <div>
                  <span><i>*</i>身份证号：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="UserFrom.idCard"
                    maxlength="18"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="10">
              <el-form-item prop="email">
                <div>
                  <span><i>*</i>电子邮箱：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="UserFrom.email"
                    type="email"
                  ></el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="10">
              <el-form-item prop="mobileReceiveStatus">
                <div>
                  <span class="electrode"><i>*</i>短信接收状态：</span>
                  <el-select
                    v-model="UserFrom.mobileReceiveStatus"
                    placeholder="请选择"
                    class="admin_tel input77"
                  >
                    <el-option
                      v-for="item in this.userlogic.TF"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="24" :md="10">
              <el-form-item prop="emailReceiveStatus">
                <div>
                  <span class="electrode"><i>*</i>邮箱接收状态：</span>
                  <el-select
                    v-model="UserFrom.emailReceiveStatus"
                    placeholder="请选择"
                    class="admin_tel input77"
                  >
                    <el-option
                      v-for="item in this.userlogic.TF"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="sureBtn">
        <el-button class="qx" @click="nosureAddUser('AddUser')" type="info"
          >取消</el-button
        >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-button class="qd" @click="sureAddUser('AddUser')" type="primary"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import userlist from "@/components/listComponents/pagerList";
import pager from "@/components/listComponents/pager.vue";
import userSearch from "@/components/searchComponents/userSearch.vue";
export default {
  name: "add",
  components: {
    userlist,
    pager,
    userSearch,
  },
  data() {
    return {
      addUserFrom: false,
      UserFrom: {
        emailReceiveStatus: false,
        mobileReceiveStatus: false,
      },
      userList: [],
      page: 1,
      numTotal: 0,
      tableHeight: "40rem",
    };
  },
  mounted() {
    this.reset();
  },
  methods: {
    handleCurrentChange: function (page = 1) {
      this.userlogic.searchObj.page = page;
      this.userlogic.getUserList().then((res) => {
        this.userList = res.userList;
        this.numTotal = res.total;
      });
    },
    reset() {
      this.userlogic.reset();
      this.handleCurrentChange();
    },
    search() {
      this.handleCurrentChange();
    },
    sureAddUser(ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.axios
            .post(this.baseURL + "User/UserAdd", this.UserFrom, this.headers())
            .then((res) => {
              this.resolveData(res.data, "add");
            });
          this.addUserFrom = false;
        } else {
          return false;
        }
      });
    },
    nosureAddUser() {
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.addUserFrom = false;
          done();
        })
        .catch((_) => {});
    },
    addUser(ref) {
      this.addUserFrom = true;
      this.resetForm(ref);
    },
    resetForm(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
    },
  },
};
</script>


