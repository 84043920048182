import proxy from "../main"
export default {
    searchObj: {
        page: 1,
        searchVal: "",
    },
    listHeader: function () {
        return [
            { type: "index", label: "序号", width: "100" },
            { prop: "userName", label: "用户昵称" },
            { prop: "mobile", label: "手机号" },
            { prop: "lastLoginDate", label: "登录时间" },
            { prop: "createDate", label: "注册时间" },

        ];
    },
    tuserList: function (page) {
        return new Promise((resolve) => {

            proxy.axios.post(proxy.baseURL + "TUser/TUserList", this.searchObj, proxy.headers())
                .then((res) => {
                    resolve(proxy.resolveData(res.data));
                })
        });

    }
}