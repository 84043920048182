<template>
  <div>
    <div class="search">
      <userSearch :reset="reset" :search="search" :numTotal="numTotal">
      </userSearch>
    </div>
    <userlist
      :tableHeader="this.userlogic.opHeader()"
      :tableData="userList"
      :page="page"
    >
      <template v-slot:btn="slotProps">
        <el-button type="warning" @click="editUser(slotProps.row)">
          编辑
        </el-button>
      </template>
    </userlist>
    <pager
      :page="page"
      :numTotal="numTotal"
      :handleCurrentChange="handleCurrentChange"
    >
    </pager>
    <el-dialog
      :model-value="editUserFrom"
      center
      top="10rem"
      :before-close="nosureEditUser"
    >
      <div class="addFunc_title">修改用户</div>
      <hr />
      <div class="xgmm_form">
        <el-form ref="editUser" :model="UserFrom" :rules="this.userlogic.rules">
          <el-row
            style="display: flex; flex-wrap: wrap; justify-content: center"
          >
            <el-col :span="24" :md="10">
              <el-form-item prop="userName">
                <div>
                  <span><i>*</i>用户名称：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="UserFrom.userName"
                    maxlength="30"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="10">
              <el-form-item prop="mobile">
                <div>
                  <span>&nbsp;&nbsp;&nbsp;<i>*</i>手机号：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="UserFrom.mobile"
                    maxlength="30"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="10">
              <el-form-item prop="idCard">
                <div>
                  <span><i>*</i>身份证号：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="UserFrom.idCard"
                    maxlength="18"
                  >
                  </el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="10">
              <el-form-item prop="email">
                <div>
                  <span><i>*</i>电子邮箱：</span>
                  <el-input
                    class="admin_tel input77"
                    v-model="UserFrom.email"
                  ></el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24" :md="10">
              <el-form-item prop="mobileReceiveStatus">
                <div>
                  <span class="electrode"><i>*</i>短信接收状态：</span>
                  <el-select
                    v-model="UserFrom.mobileReceiveStatus"
                    placeholder="请选择"
                    class="admin_tel input77"
                  >
                    <el-option
                      v-for="item in TF"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>

            <el-col :span="24" :md="10">
              <el-form-item prop="emailReceiveStatus">
                <div>
                  <span class="electrode"><i>*</i>邮箱接收状态：</span>
                  <el-select
                    v-model="UserFrom.emailReceiveStatus"
                    placeholder="请选择"
                    class="admin_tel input77"
                  >
                    <el-option
                      v-for="item in TF"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="sureBtn">
        <el-button class="qx" @click="nosureEditUser('editUser')" type="info"
          >取消</el-button
        >
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-button class="qd" @click="sureEditUser('editUser')" type="primary"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import userlist from "@/components/listComponents/pagerList";
import pager from "@/components/listComponents/pager.vue";
import userSearch from "@/components/searchComponents/userSearch.vue";
export default {
  components: {
    userlist,
    pager,
    userSearch,
  },
  data() {
    return {
      editUserFrom: false,
      UserFrom: {},
      userList: [],
      page: 1,
      numTotal: 0,
      tableHeight: "40rem",
      TF: [
        { value: false, label: "否" },
        { value: true, label: "是" },
      ],
    };
  },
  mounted() {
    this.reset();
  },
  methods: {
    handleCurrentChange: function (page, userName, email, mobile) {
      this.userlogic.searchObj.page = page;
      this.userlogic.getUserList().then((res) => {
        this.userList = res.userList;
        this.numTotal = res.total;
      });
    },
    sureEditUser(ref) {
      let aa = this.UserFrom;
      aa.id = this.mbID;
      this.$refs[ref].validate((valid) => {
        if (valid) {
          this.axios
            .post(this.baseURL + "User/UserEdit", aa, this.headers())
            .then((res) => {
              this.resolveData(res.data, "edit");
            });
          this.editUserFrom = false;
        } else {
          return false;
        }
      });
    },
    resetForm(refName) {
      if (this.$refs[refName]) {
        this.$refs[refName].resetFields();
      }
    },
    nosureEditUser() {
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.editUserFrom = false;
          done();
        })
        .catch((_) => {});
    },
    editUser(row) {
      this.editUserFrom = true;
      let obj = { ...row };
      this.UserFrom = obj;
      this.mbID = row.id;
      this.resetForm("editUser");
    },
    reset() {
      this.userlogic.reset();
      this.handleCurrentChange();
    },
    search() {
      this.handleCurrentChange();
    },
  },
};
</script>
