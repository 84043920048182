<template>
  <div>
    <roleList
      :tableData="this.rolelogic.roleList()"
      :tableHeader="this.rolelogic.opHeader()"
      :lazyLoad="this.rolelogic.getChildRoleList"
    >
      <template v-slot:btn="slotProps">
        <el-button type="primary" @click.stop="assignFunc(slotProps.row)">
          分配功能
        </el-button>
      </template>
    </roleList>
    <el-dialog
      :model-value="assignform"
      width="30rem"
      top="0rem"
      center
      :before-close="noChange"
    >
      <div class="addFunc_title">分配功能</div>
      <hr />

      <el-tree
        ref="functree"
        :data="fflist"
        show-checkbox
        node-key="id"
        :props="props"
      >
      </el-tree>
      <span slot="footer" class="sureBtn">
        <el-button class="qx" @click="noChange()" type="info">取消</el-button>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <el-button class="qd" @click="okChange()" type="primary"
          >确定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import roleList from "@/components/listComponents/treeList";
export default {
  name: "assignfun",
  components: { roleList },
  data() {
    return {
      assignform: false,
      tableHeight: "45rem",
      roleList: [],
      fflist: [],

      multipleSelection: null,
      props: {
        children: "children",
        label: "functionName",
      },
      roleid: "",
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      let aaa = {
        fpid: this.emptyId,
      };

      let roles = this.getRole();
      roles.forEach((role) => {
        this.roleList.push(role);
      });

      this.axios
        .post(
          this.baseURL + "Function/FunctionListWithChild",
          aaa,
          this.headers()
        )
        .then((res) => {
          var data = this.resolveData(res.data);
          this.fflist = data.functionList;
        });
    },
    assignFunc(row) {
      this.assignform = true;

      this.rolefuncList = [];

      let a = {
        id: row.id,
      };
      this.roleid = row.id;

      this.axios
        .post(this.baseURL + "Role/GetRoleFuncList", a, this.headers())
        .then((res) => {
          this.$refs.functree.setCheckedKeys([]);

          let data = this.resolveData(res.data);

          data.functionList.forEach((element) => {
            this.$refs.functree.setChecked(element.id, true, false);
          });
        });
    },

    noChange() {
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.assignform = false;
          done();
        })
        .catch((_) => {});
    },
    okChange() {
      this.assignform = false;

      let nodes = this.$refs.functree.getCheckedKeys();
      let a = {
        functionIdList: nodes,
        roleId: this.roleid,
      };
      this.axios
        .post(this.baseURL + "Role/AssignFunction", a, this.headers())
        .then((res) => {
          this.resolveData(res.data, "assign");
        });
    },
  },
};
</script>