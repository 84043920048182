<template>
  <div class="body">
    <basecom></basecom>
    <div class="list">
      <div class="search">
        <userSearch
          :search="search"
          :reset="reset"
          :numTotal="numTotal"
        ></userSearch>
      </div>
      <userlist
        :tableHeader="this.userlogic.listHeader()"
        :tableData="userList"
        :page="page"
      ></userlist>
      <pager
        :page="page"
        :numTotal="numTotal"
        :handleCurrentChange="handleCurrentChange"
      >
      </pager>
    </div>
  </div>
</template>

<script>
import basecom from "@/components/base";
import userlist from "@/components/listComponents/pagerList";
import pager from "@/components/listComponents/pager.vue";
import userSearch from "@/components/searchComponents/userSearch.vue";
export default {
  components: {
    basecom,
    userlist,
    pager,
    userSearch,
  },
  data() {
    return {
      userList: [],
      page: 1,
      numTotal: 0,
    };
  },
  mounted() {
    this.handleCurrentChange();
  },
  methods: {
    handleCurrentChange: function (page = 1) {
      this.page = page;
      this.userlogic.getUserList().then((res) => {
        this.userList = res.userList;
        this.numTotal = res.total;
      });
    },
    reset() {
      this.userlogic.reset();
      this.handleCurrentChange();
    },
    search() {
      this.handleCurrentChange();
    },
  },
};
</script>