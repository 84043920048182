<template>
  <div>
    <el-row :gutter="10" type="flex" align="middle">
      <el-col :span="3.5">
        <div class="listTitle">
          用户列表·共<span style="color: coral">{{ numTotal }}</span
          >人
        </div>
      </el-col>
      <el-col :span="3.5">
        <el-input
          v-model="userName"
          placeholder="请输入姓名"
          @change="setuserName"
          @keyup.enter.native="search"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </el-col>
      <el-col :span="3.5">
        <el-input
          v-model="mobile"
          type="Number"
          placeholder="请输入手机号"
          maxlength="11"
          @change="setmobile"
          @keyup.enter.native="search"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </el-col>
      <el-col :span="3.5">
        <el-input
          v-model="email"
          placeholder="请输入邮箱"
          @change="setemail"
          @keyup.enter.native="search"
        >
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
        </el-input>
      </el-col>
      <el-col :span="1.5">
        <el-button @click="search" type="primary">查询</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button @click="reset" type="success">重置</el-button>
      </el-col>
      <el-col :span="1.5">
        <el-button @click="addUser" v-if="addUserBtn" type="warning"
          >添加用户</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addUserBtn: false,
      userName: "",
      email: "",
      mobile: "",
    };
  },
  props: {
    numTotal: {
      type: Number,
      default: 0,
    },
    search: {
      type: Function,
      default: function () {},
    },
    reset: {
      type: Function,
      default: function () {},
    },
    addUser: {
      type: Function,
      default: function () {},
    },
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      let href = window.location.href;
      if (href.indexOf("addUser") > 0) {
        this.addUserBtn = true;
      }
      this.userlogic.reset();
    },
    setuserName() {
      this.userlogic.searchObj.userName = this.userName;
      let interval = setInterval(() => {
        if (this.userlogic.searchObj.userName === "") {
          this.userName = "";
          clearInterval(interval);
        }
      }, 1000);
    },
    setemail() {
      this.userlogic.searchObj.email = this.email;
      let interval = setInterval(() => {
        if (this.userlogic.searchObj.email == "") {
          this.email = "";
          clearInterval(interval);
        }
      }, 1000);
    },
    setmobile() {
      this.userlogic.searchObj.mobile = this.mobile;
      let interval = setInterval(() => {
        if (this.userlogic.searchObj.mobile == "") {
          this.mobile = "";
          clearInterval(interval);
        }
      }, 1000);
    },
  },
};
</script>


