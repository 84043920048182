<template>
  <div class="list">
    <articleSearch :numTotal="numTotal" :search="search" />
    <pagerlist
      :tableHeader="this.articlelogic.opHeader()"
      :tableData="articleList"
    >
      <template v-slot:btn="slotProps">
        <el-button type="primary" @click.stop="showEditArticle(slotProps.row)">
          修改
        </el-button>
      </template>
    </pagerlist>
    <pager
      :handleCurrentChange="handlerCurrentPage"
      :page="page"
      :numTotal="numTotal"
    />
    <el-dialog
      :model-value="showDialog"
      width="80rem"
      center
      :before-close="closeShowDial"
    >
      <div class="addFunc_title">修改文章</div>
      <hr />
      <el-form
        ref="addArticleForm"
        :model="articleForm"
        :rules="this.articlelogic.rules"
      >
        <el-row :gutter="20">
          <el-col :span="2" align="right"> 文章标题： </el-col>
          <el-col :span="22">
            <el-form-item prop="articleTitle">
              <el-input
                v-model="articleForm.articleTitle"
                placeholder="请输入文章标题"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="2" align="right"> 文章简介： </el-col>
          <el-col :span="22">
            <el-form-item prop="articleIntro">
              <el-input
                v-model="articleForm.articleIntro"
                placeholder="请输入文章简介"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="2" align="right"> 文章编码： </el-col>
          <el-col :span="22">
            <el-form-item prop="articleCode">
              <el-input
                v-model="articleForm.articleCode"
                placeholder="特殊类文章输入文章编码"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="2" align="right"> 文章分类： </el-col>
          <el-col :span="22">
            <el-form-item prop="articleCategoryId">
              <el-select
                v-model="articleForm.articleCategoryId"
                placeholder="请选择"
                class="admin_tel input77"
              >
                <el-option
                  v-for="item in this.categoryList"
                  :key="item.id"
                  :label="item.categoryName"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="2" align="right"> 文章缩略图： </el-col>
          <el-col :span="22">
            <el-form-item prop="articleImg">
              <img
                class="articleimg"
                :src="
                  articleForm.articleImg == ''
                    ? baseURL + 'Upload/img/no_data.png'
                    : baseURL + articleForm.articleImg
                "
                alt=""
              />
              <el-upload
                ref="appfile"
                drag
                class="upload-demo"
                :http-request="upload"
                action="none"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将图片文件拖到此处，或<em>点击上传</em>
                </div>
                <div
                  class="el-upload__tip"
                  slot="tip"
                  style="color: crimson; font-size: 15px; text-align: center"
                >
                  *只能上传图片文件
                </div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="2" align="right"> 文章内容： </el-col>
          <el-col :span="22">
            <el-form-item prop="articleContents">
              <editor
                :htmlContent="articleContents"
                :handleChange="handleChange"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="20">
          <el-col :span="24" align="center">
            <el-button
              type="primary"
              @click.stop="saveArticle('addArticleForm')"
              >确定</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import editor from "@/components/articleEditor.vue";
import articleSearch from "@/components/searchComponents/articleSearch.vue";
import pagerlist from "@/components/listComponents/pagerList.vue";
import pager from "@/components/listComponents/pager.vue";

export default {
  components: { pagerlist, pager, articleSearch, editor },
  data() {
    return {
      articleList: [],
      page: 1,
      numTotal: 0,
      showDialog: false,
      articleContents: "",
      flag: false,
      articleForm: {
        id: "",
        articleTitle: "",
        articleIntro: "",
        articleContents: "",
        articleImg: "",
        articleCode: "",
      },
      baseURL: this.baseURL,
      categoryList: [],
    };
  },
  mounted() {
    this.handlerCurrentPage();
    this.articlelogic.categoryList().then((res) => {
      this.categoryList = res.articleCategoryList;
    });
  },
  methods: {
    handlerCurrentPage(page = 1) {
      this.articlelogic.searchObj.page = page;
      this.articlelogic.articleList().then((res) => {
        this.articleList = res.articleList;
        this.numTotal = res.total;
      });
    },
    search: function () {
      this.handlerCurrentPage();
    },
    showEditArticle: function (row) {
      this.showDialog = true;
      let aa = { id: row.id };
      this.axios
        .post(this.baseURL + "Article/ArticleDetail", aa, this.headers())
        .then((res) => {
          let data = this.resolveData(res.data);
          let contents = data.articleContents
          const re = `<img src="${this.baseURL}Upload`
          this.articleContents = contents.replace(/<img src=".*?\Upload/g,re)
          this.articleForm = data;
        });
    },
    closeShowDial(ref) {
      this.$confirm("确认要关闭吗？")
        .then((_) => {
          this.showDialog = false;
          done();
        })
        .catch((_) => {});
    },
    handleChange: function (editor) {
      this.articleForm.articleContents = editor.getHtml();
    },
    saveArticle: function (ref) {
      this.$refs[ref].validate((valid) => {
        if (valid) {
          delete this.articleForm.readCount;
          delete this.articleForm.createDate;
          console.log(this.articleForm);
          this.axios
            .post(
              this.baseURL + "Article/EditArticle",
              this.articleForm,
              this.headers()
            )
            .then((res) => {
              this.resolveData(res.data, "edit");
            });
        }
      });
    },
    upload: function (file) {
      let fileobj = file.file;
      let filetype = fileobj.name.substring(fileobj.name.lastIndexOf(".") + 1);
      if (
        filetype != "jpg" &&
        filetype != "jpep" &&
        filetype != "JPG" &&
        filetype != "JPEG" &&
        filetype != "png" &&
        filetype != "PNG"
      )
        this.$message({
          message: "上传文件必须为图片文件",
          type: "error",
        });
      else {
        let formdata = new FormData();
        formdata.append("file", fileobj);
        this.axios
          .post(this.baseURL + "Upload/BaseUpload", formdata, this.headers())
          .then((res) => {
            this.articleForm.articleImg = this.resolveData(res.data);
          });
      }
    },
  },
};
</script>

<style scoped>
.list .el-row {
  margin-top: 1rem;
  line-height: 2rem;
}
.el-col {
  color: white !important;
}
.list >>> .el-dialog span {
  color: black !important;
}
.list >>> .el-dialog .el-button span {
  color: white !important;
}
.articleimg {
  width: 8rem;
  margin-right: 2rem;
}
.upload-demo {
  height: 5rem;
}
.upload-demo >>> .el-upload-dragger {
  height: 5rem !important;
}
</style>