 <template>
  <el-table
    class="table"
    :data="tableData"
    ref="listTable"
    width="100%"
    lazy
    row-key="id"
    :load="lazyLoad"
    :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
  >
    <el-table-column
      v-for="(th, key) in tableHeader"
      :type="th.type  ? 'string' : th.type"
      :key="key"
      :prop="th.prop"
      :label="th.label"
      min-width="50"
      :width="th.width"
      :align="th.align == null ? 'center' : th.align"
    >
      <template v-slot="scope">
        <span v-if="th.type == 'index'">{{ scope.$index + 1 }}</span>
        <slot
          :name="th.prop"
          v-else-if="th.slot"
          :row="scope.row"
          :index="scope.$index"
        ></slot>
        <span v-else-if="!scope.row[th.prop]"> - </span>

        <span v-else>
          {{
            scope.row[th.prop] == "2000-01-01 00:00:00"
              ? "-"
              : scope.row[th.prop]
          }}</span
        >
      </template>
    </el-table-column>
  </el-table>
</template>
<script>
export default {
  name: "treelist",
  components: {},
  props: {
    tableData: {
      type: Array,
      default: [],
    },
    tableHeader: {
      type: Array,
      default: [],
    },
    lazyLoad: {
      type: Function,
      default: function (row, treeNode, resolve) {},
    },
  },
  mounted() {
    setTimeout(() => {
      let i = document.querySelector(".el-table__expand-icon");
      i.click();
    }, 500);
  },
};
</script>
<style scoped>
.el-table >>> .el-table__expand-icon {
  color: white;
}
</style>